.wrapper {
  position: relative;

  display: flex;
  flex-direction: column;
  min-height: 100vh;
  /* stylelint-disable-next-line */
  min-height: calc(100 * var(--vh, 1vh));

  .header,
  .footer {
    flex-shrink: 0;
  }

  main {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
}

.visually-hidden {
  position: absolute;

  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;

  white-space: nowrap;

  border: 0;

  clip: rect(0 0 0 0);
  clip-path: inset(100%);
}

.scroll-lock-ios {
  position: fixed;

  overflow: hidden;
}

.scroll-lock {
  overflow: hidden;
}

.no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.thin-scrollbar {
  overflow: auto;
  scrollbar-color: $color-dark-slate-blue $color-transparent;
  scrollbar-width: thin;

  @media (hover: hover), screen and (min-width: 0\0) {
    &::-webkit-scrollbar {
      width: size(4px);
      height: size(4px);
      background-color: $color-transparent;

      @include vp-1439 {
        width: 4px;
        height: 4px;
      }
    }

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: none;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $color-dark-slate-blue;
      border-radius: size(8px);

      @include vp-1439 {
        border-radius: 8px;
      }
    }
  }
}

.no-transition {
  /* stylelint-disable-next-line */
  transition: none !important;
}

.no-pointer {
  pointer-events: none;
}
