.main-nav {
  position: absolute;
  top: size(41px);
  right: size(34px);

  @include vp-1439 {
    top: 41px;
    right: 34px;
  }
  @include vp-767 {
    top: 30px;
    right: 25px;
  }

  &__overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    background: $color-default-white;
    visibility: hidden;
    opacity: 0;

    transition: opacity $trans-default, visibility $trans-default;

    pointer-events: none;

    @include vp-767 {
      display: none;
    }

    .is-open & {
      visibility: visible;
      opacity: 0.55;

      pointer-events: all;

      backdrop-filter: blur(1px);
    }
  }

  &__container {
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 100%;
    flex-direction: column;
  }

  &__wrapper {
    position: fixed;
    top: 0;
    right: 0;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: size(538px);
    height: 100%;
    min-height: 100vh;
    padding-top: size(251px);
    padding-bottom: size(65px);
    overflow-x: hidden;
    overflow-y: auto;

    background-color: $color-dark-slate-blue;
    transform: translateX(100%);
    visibility: hidden;
    opacity: 0;

    transition: transform $trans-default, opacity $trans-default, visibility $trans-default;

    @include vp-1439 {
      width: 538px;
      padding-top: 251px;
      padding-bottom: 65px;
    }
    @include vp-767 {
      width: 100%;
      min-height: auto;
      padding-top: 178px;
      padding-bottom: 47px;
    }

    .is-open & {
      transform: translateX(0);
      visibility: visible;
      opacity: 1;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 0 size(136px);
    padding: 0;

    list-style: none;

    @include vp-767 {
      margin-bottom: 51px;
    }
  }

  &__item {
    margin-bottom: size(42px);

    font-weight: 400;
    font-size: size(36px);
    line-height: 61%;
    font-family: $ff-mossport;
    text-transform: uppercase;

    @include vp-1439 {
      margin-bottom: 42px;

      font-size: 36px;
    }
    @include vp-767 {
      max-width: 250px;
      margin-bottom: 31px;

      font-size: 32px;
      line-height: 100%;
      text-align: center;
    }
  }

  &__link {
    color: $color-default-white;
    transition: opacity $trans-default;

    &.is-active {
      pointer-events: none;
      opacity: 0.6;
    }

    @include has-hover {
      opacity: 0.6;
    }
  }

  &__bg-wrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: -1;

    width: 100%;
    height: 100%;
    min-height: size(900px);

    @include vp-1439 {
      min-height: 900px;
    }

    @include vp-767 {
      min-height: 768px;
    }
  }

  &__bg {
    position: absolute;


    svg {
      height: auto;
    }

    &--top-left {
      top: 0;
      left: -#{size(10px)};

      @include vp-1439 {
        left: -10px;
      }
      @include vp-767 {
        left: -13px;
      }

      svg {
        width: size(200px);

        @include vp-1439 {
          width: 200px;
        }
        @include vp-767 {
          width: 163px;
        }
      }
    }

    &--top-right {
      top: size(80px);
      right: -#{size(27px)};

      @include vp-1439 {
        top: 80px;
        right: -27px;
      }
      @include vp-767 {
        top: 85px;
        right: -33px;
      }

      svg {
        width: size(178px);

        @include vp-1439 {
          width: 178px;
        }
        @include vp-767 {
          width: 150px;
        }
      }
    }

    &--bottom-left {
      bottom: 0;
      left: -#{size(90px)};

      @include vp-1439 {
        left: -90px;
      }
      @include vp-1024 {
        left: -77px;
      }
      @include vp-767 {
        bottom: 43px;
        left: -107px;
      }

      svg {
        width: size(448px);

        @include vp-1439 {
          width: 448px;
        }
        @include vp-767 {
          width: 360px;
        }
      }
    }

    &--bottom-right {
      right: -#{size(40px)};
      bottom: size(116px);

      @include vp-1439 {
        right: -40px;
        bottom: 116px;
      }
      @include vp-1024 {
        right: -58px;
      }
      @include vp-767 {
        right: -75px;
        bottom: 46px;
      }

      svg {
        width: size(195px);

        @include vp-1439 {
          width: 195px;
        }
        @include vp-767 {
          width: 180px;
        }
      }
    }
  }

  &__copyright {
    max-width: size(243px);
    margin: 0;

    font-weight: 400;
    font-size: size(12px);
    line-height: 158%;
    text-align: center;
    color: $color-default-white;

    @include vp-1439 {
      max-width: 243px;

      font-size: 12px;
    }

    a {
      color: inherit;
      border-bottom: 1px solid $color-default-white;

      transition: opacity $trans-default;

      @include has-hover {
        opacity: 0.6;
      }
    }
  }

  &__toggle {
    position: relative;
    z-index: 20;

    display: flex;
    align-items: center;
    width: size(32px);
    height: size(32px);
    padding: 0;

    background-color: transparent;
    border: none;
    outline: none;

    transition: opacity $trans-default;

    @include vp-1439 {
      width: 32px;
      height: 21px;
    }
    @include vp-767 {
      top: -4px;
      left: 5px;

      width: 25px;
    }

    &[aria-pressed="false"] {
      @include has-hover {
        .main-nav__menu-toggle-ic:nth-child(2) {
          transform: translateX(15%);
        }
      }
    }

    &[aria-pressed="true"] {
      @include has-hover {
        opacity: 0.7;
      }
    }
  }

  &__menu-toggle-in {
    position: absolute;

    width: size(32px);
    height: size(32px);

    cursor: pointer;

    @include vp-1439 {
      width: 32px;
      height: 32px;
    }
    @include vp-767 {
      width: 25px;
      height: 25px;
    }

    &::before,
    &::after {
      content: "";

      display: block;
      width: size(3px);
      height: size(45px);

      background-color: $color-default-white;
      transform-origin: center center;

      @include vp-1439 {
        width: 3px;
      }

      @include transition-mix;
      @include vp-1439 {
        height: 32px;
      }
      @include vp-767 {
        width: 2px;
        height: 25px;
      }
    }

    &::before {
      transform: rotate(-90deg);
      opacity: 0;

      @include position-absolute($top: -#{size(22px)}, $left: size(15px));
      @include vp-1439 {
        @include position-absolute($top: -5px, $left: 15px);
      }
      @include vp-767 {
        top: 0;
        left: 6px;
      }
    }

    &::after {
      transform: rotate(90deg);
      opacity: 0;

      @include position-absolute($top: -#{size(22px)}, $right: size(15px));
      @include vp-1439 {
        @include position-absolute($top: -5px, $right: 15px);
      }
      @include vp-767 {
        top: 0;
        left: 6px;
      }
    }


    .main-nav__toggle[aria-pressed="true"] & {
      &::before,
      &::after {
        opacity: 1;

        @include transition-mix($delay: 0.2s);
      }

      &::before {
        top: -#{size(10px)};

        transform: rotate(-45deg);

        @include vp-1439 {
          top: -7px;
        }
        @include vp-767 {
          top: -4px;
        }
      }

      &::after {
        top: -#{size(10px)};

        transform: rotate(45deg);

        @include vp-1439 {
          top: -7px;
        }
        @include vp-767 {
          top: -4px;
        }
      }
    }
  }

  &__menu-toggle-ic {
    width: 100%;
    height: 3px;

    background-color: $color-alizarin;

    @include transition-mix($delay: 0.2s);
    @include vp-767 {
      height: 2px;
    }

    &:nth-child(1) {
      @include position-absolute($top: 0, $left: 0);
    }

    &:nth-child(2) {
      @include position-absolute($top: size(7px), $left: 0);
      @include vp-1439 {
        @include position-absolute($top: 7px, $left: 0);
      }
    }

    &:nth-child(3) {
      @include position-absolute($top: size(14px), $left: 0);
      @include vp-1439 {
        @include position-absolute($top: 14px, $left: 0);
      }
    }

    .main-nav__toggle[aria-pressed="true"] & {
      opacity: 0;

      @include transition-mix;

      &:nth-child(1) {
        top: size(6px);

        @include vp-1439 {
          top: 6px;
        }
      }

      &:nth-child(3) {
        top: size(6px);

        @include vp-1439 {
          top: 6px;
        }
      }
    }
  }
}
