.share-form {
  position: relative;
  background-color: $color-default-white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 size(7px);
  //box-shadow: 0 0 30px rgba($color-heather, 0.15);

  @include vp-1439 {
    //width: 680px;
    padding: 48px 0 70px;
  }

  @include vp-1023 {
    //width: 536px;
    padding: 21px 0 62px;
  }

  @include vp-767 {
    width: 100%;
    padding: 27px 0 18px;
  }

  form {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__wrp {
    display: none;
    grid-template-columns: repeat(12, [col-start] 1fr);

    &.is-active {
      display: grid;
    }

    &--img {
      .share-form__container {
        grid-row: 2/3;
      }

      .share-form__sub-header {
        margin-bottom: size(47px);

        @include vp-1279 {
          text-align: center;
        }
      }

      .share-aside {
        grid-column: col-start 10/span 3;
        max-width: size(300px);
        margin-top: size(143px);
        height: 100%;
        margin-left: auto;

        //@include vp-1439 {
        //  grid-column: col-start 11/span 3;
        //}

        @include vp-1279 {
          margin-top: 60px;
          margin-right: auto;
          margin-left: auto;
          max-width: size(784px);
        }

        @include vp-767 {
          padding-left: 0;
          padding-right: 0;
          margin-top: 26px;

          .share-form__sub-header {
            display: none;
          }
        }
      }
    }

    @include vp-1279 {
      &.is-active {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
  }

  &__container {
    grid-column: col-start 3 / span 8;
    grid-row: 2 / 4;
    max-width: size(784px);
    width: 100%;
    padding: 0 size(10px) 0 size(95px);

    @include vp-1439 {
      grid-column: col-start 2/span 8;
    }

    @include vp-1279 {
      padding: 0 24px;
    }
  }

  &__next {
    appearance: none;
    border: none;
    cursor: pointer;
    margin:  size(51px) auto;
    padding: size(17px) size(76px);
    font-family: $ff-mossport;
    font-weight: 400;
    font-size: size(22px);
    line-height: 109%;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    color: $color-default-white;
    background-color: $color-alizarin;
    opacity: 1;
    transition: opacity $trans-default;

    @include has-hover {
      opacity: 0.7;
    }

    &.is-hidden {
      display: none;
    }
  }

  &__inputs {
    display: flex;
    flex-direction: column;
    margin-top: size(43px);

    @include vp-767 {
      margin-top: size(38px);
    }
  }

  &__header {
    margin: 0 0 ;
    padding: 0 size(27px);
    font-weight: 400;
    font-family: $ff-mossport;
    font-size: size(68px);
    line-height: 100%;
    text-align: center;
    letter-spacing: 0.01em;
    text-transform: uppercase;

    @include vp-1439 {
      margin: 0 0 28px;
      padding: 0 107px;
      font-size: 36px;
    }

    @include vp-1023 {
      margin: 0 0 15px;
      padding: 0 45px;
    }

    @include vp-767 {
      margin: 0;
      padding: 0;
      font-size: size(48px);
      line-height: 102%;
    }
  }

  form {
    //padding: 0 size(107px);
    //
    //@include vp-1439 {
    //  padding: 0 107px;
    //}
    //
    //@include vp-1023 {
    //  padding: 0 55px;
    //}
    //
    //@include vp-767 {
    //  padding: 0 24px;
    //}
  }

  &__sub-header {
    font-family: $ff-mossport;
    font-weight: 400;
    font-size: size(32px);
    line-height: 112%;
    text-align: center;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    color: $color-nero;
    margin-top: size(10px);
    margin-bottom: size(27px);

    @include vp-767 {
      font-size: 20px;
    }
  }

  &__custom-img {
    border: 1px solid $color-alizarin;
    display: flex;
    justify-content: center;
    padding: size(70px) size(30px) size(57px);
    width: size(600px);
    min-height: size(540px);
    margin: 0 auto;

    img, svg {
      max-height: size(485px);
      max-width: 100%;
    }

    @include vp-767 {
      width: 100%;
      height: auto;
      min-height: unset;
      padding-bottom: 68%;
      position: relative;

      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-height: 235px;
        max-width: 235px;
      }
    }
  }

  &__svg-wrapper {
    height: 100%;
    width: auto;
  }

  &__result {
    position: relative;
    box-shadow: 0 0 30px rgba(177, 182, 187, 0.15);
    padding: size(33px) size(50px) size(50px);
    margin: 0 auto size(69px);
    max-width: size(600px);

    @include vp-767 {
      padding: 24px;
      padding-top: 51px;
      margin-bottom: 34px;
    }
  }

  &__img-selector {
    height: auto;
    //display: flex;
    //flex-wrap: wrap;
    //row-gap: size(29px);
    //align-items: stretch;

    //.custom-radio {
    //  width: 33.33%;
    //}

    .error {
      width: 100%;
      font-size: size(12px);
      line-height: 117%;
      color: $color-alizarin;

      opacity: 0;

      transition: all $trans-default;

      @include vp-1439 {
        font-size: 12px;
      }
    }

    &.is-invalid .error {
      opacity: 1;
    }

    //@include vp-1439 {
    //  .custom-radio {
    //    width: 50%;
    //  }
    //}
    //
    //@include vp-1279 {
    //  flex-wrap: nowrap;
    //  overflow: scroll;
    //  gap: 12px;
    //
    //  .custom-radio {
    //    width: 95px;
    //    min-width: 95px;
    //  }
    //}
  }

  &__result-name {
    font-weight: 700;
    font-size: size(14px);
    line-height: 200%;
    color: $color-nero;
    text-align: center;
    margin: 0 0 size(23px) 0;
  }

  &__result-title {
    font-family: $ff-mossport;
    font-weight: 400;
    font-size: size(32px);
    line-height: 112%;
    text-align: center;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    color: $color-alizarin;
    margin: 0 auto size(30px);
    max-width: size(300px);
  }

  &__result-info {
    font-weight: 400;
    font-size: size(16px);
    line-height: 171%;
    text-align: center;
    color: $color-nero;
    position: absolute;
    width: 100%;
    max-width: size(400px);
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0 ;
    padding-bottom: size(30px);
    user-select: auto;
  }

  &__result-story {
    font-weight: 400;
    font-size: 14px;
    line-height: 171%;
    color: $color-nero;
    margin: 0;
    word-break: break-word;
  }

  &__back-img {
    pointer-events: none;
    user-select: none;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin: 0 auto size(56px);

    svg,
    img {
      height: size(485px);
      width: auto;
      pointer-events: none;
      user-select: none;
      opacity: 0.8;
      filter: blur(7.5px);
      position: relative;
      z-index: 1;
      min-height: 335px;
    }
  }

  &__leave-email {
    font-family: $ff-mossport;
    font-weight: 400;
    font-size: 36px;
    line-height: 100%;
    text-align: center;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    color: $color-nero;
    margin: 0 0 size(11px) 0;

    @include vp-767 {
      font-size: 20px;
      line-height: 28px;
      padding: 0 30px;
      margin-bottom: 6px;
    }
  }

  &__moderation {
    font-weight: 400;
    font-size: size(14px);
    line-height: 171%;
    text-align: center;
    color: $color-nero;
    margin: 0 0 size(44px) 0;

    @include vp-767 {
      margin-bottom: 35px;
    }
  }

  &__input {
    margin: 0 0 size(12px);

    @include vp-1439 {
      margin: 0 0 12px;
    }

    // @include vp-767 {
    //   margin: 0 0 6px;
    // }

    &--email {
      max-width: size(466px);
      margin-right: auto;
      margin-left: auto;

      @include vp-1439 {
        margin-right: auto;
        margin-left: auto;
      }

      @include vp-767 {
        margin-bottom: 30px;
      }
    }
  }

  &__select {
    margin: 0 0 size(12px);

    @include vp-1439 {
      margin: 0 0 12px;
    }

    // @include vp-767 {
    //   margin: 0 0 6px;
    // }
  }

  &__textarea {
    margin: 0;

    //@include vp-1439 {
    //  margin: 0 0 10px;
    //}

    @include vp-1023 {
      margin: 0;
    }

    textarea {
      min-height: size(425px);

      //@include vp-1439 {
      //  min-height: 136px;
      //}

      //@include vp-1439 {
      //  min-height: 168px;
      //}

      @include vp-767 {
        min-height: 235px;
      }

      // @include vp-374 {
      //   min-height: 345px;
      // }
    }
  }

  &__btn {
    position: relative;
    left: calc(50% - #{size(12px)});
    transform: translateX(-50%);
    margin-top: size(36px);

    @include vp-1439 {
      left: calc(50% - 12px);
    }

    @include vp-1023 {
      left: calc(50% - 2px);
    }

    @include vp-767 {
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &__message {
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: size(687px);
    padding: size(38px) 0 0;

    @include vp-1439 {
      min-height: 687px;
      padding: 38px 0 0;
    }

    &.show {
      display: flex;
    }
  }

  &__message-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: size(91px);
    height: size(91px);
    border-radius: 50%;
    background-color: $color-alizarin;

    @include vp-1439 {
      width: 91px;
      height: 91px;
    }

    svg {
      flex-shrink: 0;
      width: size(40px);
      height: size(29px);
      fill: $color-transparent;

      @include vp-1439 {
        width: 40px;
        height: 29px;
      }
    }
  }

  &__message-header {
    margin: size(30px) 0;
    font-weight: 400;
    font-family: $ff-mossport;
    font-size: size(36px);
    line-height: 125%;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    text-align: center;

    @include vp-1439 {
      margin: 30px 0;
      font-size: 36px;
    }
  }

  &__message-text {
    max-width: size(330px);
    margin: 0;
    line-height: 171%;
    text-align: center;

    @include vp-1439 {
      max-width: 330px;
    }

    @include vp-767 {
      padding: 0 16px;
    }
  }

  &__list-title {
    display: block;
    padding: size(2px) 0 size(14px);
    font-weight: 700;
    line-height: 171%;

    @include vp-1439 {
      padding: 2px 0 14px;
    }
  }

  &__preview {
    max-height: 0;
    overflow: hidden;
    transition: all $trans-default;
  }

  &__preview-list {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &__preview-item {
    position: relative;
    // display: flex;
    // align-items: center;
    margin: 0 0 size(6px);
    padding: 0 0 size(6px);
    overflow: hidden;
    transition: all 0.1s ease;

    @include vp-1439 {
      margin: 0 0 6px;
      padding: 0 0 6px;
    }

    &.is-invalid {
      img {
        filter: grayscale(100%);
      }

      .share-form {
        &__preview-title {
          color: rgba($color-nero, 0.5);
        }

        &__preview-subtitle {
          font-size: size(12px);
          color: $color-alizarin;

          @include vp-1439 {
            font-size: 12px;
          }
        }
      }
    }
  }

  &__preview-img-wrp {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    // flex-shrink: 0;
    width: size(47px);
    height: size(37px);
    border-radius: size(5px);
    overflow: hidden;

    @include vp-1439 {
      width: 47px;
      height: 37px;
      border-radius: 5px
    }

    img {
      // max-height: 100%;
      object-fit: contain;
    }
  }

  &__preview-title-wrp {
    display: flex;
    flex-direction: column;
    margin: size(6px) 0 0 size(63px);
    padding: 0 size(49px) 0 0;

    @include vp-1439 {
      margin: 6px 0 0 63px;
      padding: 0 32px 0 0;
    }
  }

  &__preview-title {
    margin: 0 0 size(8px);
    line-height: 100%;
    color: $color-nero;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    @include vp-1439 {
      margin: 0 0 8px;
    }
  }

  &__preview-subtitle {
    font-size: size(11px);
    line-height: 100%;
    color: rgba($color-nero, 0.5);
    margin: 0 0 size(-2px);

    @include vp-1439 {
      font-size: 11px;
      margin: 0 0 -2px;
    }
  }

  &__preview-delete {
    position: absolute;
    top: 2%;
    right: size(-8px);
    // transform: translateY(-50%);
    // margin: size(-8px) size(-10px) 0 auto;
    padding: size(10px);
    transition: all $trans-default;

    @include vp-1439 {
      right: -8px;
      // margin: -8px -10px 0 auto;
      padding: 10px;
    }

    @include has-hover {
      opacity: 0.5;
    }

    svg {
      width: size(9px);
      height: size(9px);

      @include vp-1439 {
        width: 9px;
        height: 9px;
      }
    }
  }

  &__error-msg {
    position: absolute;
    bottom: size(45px);
    margin: 0;
    font-size: size(12px);
    line-height: 117%;
    color: $color-alizarin;

    opacity: 0;
    visibility: hidden;

    transition: all $trans-default;

    @include vp-1439 {
      bottom: 45px;
      font-size: 12px;
    }

    @include vp-767 {
      bottom: 5px;
    }

    .error & {
      opacity: 1;
      visibility: visible;
    }
  }

  &__agreement {
    margin: size(68px) 0 size(35px);
    font-weight: 400;
    font-size: size(14px);
    line-height: 114%;
    text-align: center;

    @include vp-767 {
      margin-top: 80px;
      margin-bottom: 25px;
    }

    span {
      display: block;
    }

    a {
      color: $color-alizarin;
      transition: color $trans-default;

      @include has-hover {
        color: $color-dark-slate-blue;
      }
    }

    &--bottom {
      margin: 0;
      margin-left: -#{size(60px)};
      margin-right: -#{size(60px)};

      @include vp-1023 {
        margin-left: -20px;
        margin-right: -20px;
      }

      @include vp-767 {
        margin: 0;
      }
    }
  }

  &__banner {
    margin: 0 size(107px) size(29px);

    @include vp-1023 {
      margin: 0 size(55px) size(25px);
    }

    @include vp-767 {
      margin: 0 24px 32px;
    }
  }

  &__attention {
    font-weight: 400;
    font-size: size(14px);
    line-height: 100%;
    text-align: center;
    color: $color-alizarin;
    margin: 0 0 size(18px) 0;
  }

  .custom-input {
    input {
      min-height: size(54px);
      padding-left: size(33px);

      @include vp-767 {
        min-height: size(48px);
      }
    }

    label {
      padding-bottom: 0;
    }

    &__label {
      left: size(33px);
    }
  }

  .share-form__input--email {
    label {
      padding-bottom: size(10px);
    }
  }

  .custom-select__placeholder {
    left: size(33px);
  }

  .custom-select__button {
    margin-bottom: 0;
    padding-left: size(33px);
  }

  .custom-select__error,
  .custom-input__error {
    height: 0;
  }

  .custom-select.is-invalid .custom-select__error,
  .custom-input.is-invalid .custom-input__error {
    height: auto;
  }

  .custom-input--file {
    .custom-input__label {
      position: static;
    }
  }

  .custom-textarea label textarea {
    margin-left: size(33px);
  }

  .custom-textarea__label {
    padding-left: size(33px);
  }

  &__btns {
    display: grid;
    grid-template-columns: repeat(12,[col-start] 1fr);
    margin: size(27px) auto 0;
    width: 100%;

    @include vp-767 {
      display: flex;
      flex-direction: column;
      margin-top: 12px;
    }

    @include vp-1279 {
      display: flex;
      justify-content: center;
    }
  }

  &__wrapper {
    grid-column: col-start 3/span 8;
    padding: 0 size(10px) 0 size(95px);
    max-width: size(784px);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include vp-1439 {
      grid-column: col-start 2/span 8;
    }

    @include vp-1279 {
      padding: 0 24px;
    }
  }

  .btn-square {
    margin:  0 auto;
    min-width: size(239px);
    transition: opacity color $trans-default;

    @include vp-767 {
      padding-right: 60px;
      padding-left: 60px;
      min-width: 239px;
    }

    &.is-disabled {
      pointer-events: none;
      background-color: $color-nero;
      opacity: 0.5;
    }
  }

  .custom-select__list {
    bottom: 1px;
  }

  &.is-result {
    .share-form__wrp.is-active,
    .share-form__btns {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0 size(30px);
    }

    .share-form__container,
    .share-form__wrapper {
      padding: 0 size(10px);

      @include vp-767 {
        padding: 0;
      }
    }
  }
}
