.timeline-slide {
  display: grid;
  padding-top: size(105px);
  padding-bottom: size(80px);
  padding-left: size(60px);

  grid-column-gap: size(14px);
  grid-template-columns: size(355px) auto 1fr;
  grid-template-rows: auto 1fr;

  min-height: size(750px) !important;

  @include vp-1599 {
    padding-top: size(135px);
    padding-bottom: size(116px);
  }

  @include vp-1023 {
    padding-left: 30px;
    min-height: auto;
  }
  @include vp-767 {
    background-color: #F3F3F3;
    padding-top: 123px;
    padding-right: 0;
    padding-bottom: 30px;
    padding-left: 0;

    display: flex;
    flex-direction: column;
  }

  &__img-inner {
    position: relative;
    width: auto;
    margin-left: auto;
    margin-top: auto;
    height: 100%;

    @include vp-767 {
      width: 100%;
    }
  }

  &__img {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 0;

    width: 80%;
    // adding-bottom: 50%;

    display: flex;
    flex-direction: column;
    height: calc(100% - #{size(50px)});
    padding-bottom: 0;

    @include vp-1439 {
      height: auto;
    }

    @include vp-767 {
      position: relative;

      width: 100%;
      padding-bottom: 0;
      padding-top: 20px;
    }

    img {
      position: relative;
      height: 100%;
      width: auto;
      object-fit: contain;

      @include vp-767 {
        position: relative;
        width: 100%;
      }
    }

    .timeline-slide--1920 & {
      bottom: -#{size(10px)};

      @include vp-767 {
        bottom: 0;
      }
    }
  }

  &__desc {
    position: relative;
    z-index: 1;
  }

  &__history-btn {
    position: relative;
    z-index: 1;

    margin-top: -#{size(13px)};

    @include vp-767 {
      margin: -3px auto 0;

      order: 1;
    }
  }

  &__title {
    margin: 0 0 size(14px);

    font-weight: 400;
    font-size: size(36px);
    line-height: 106%;
    font-family: $ff-mossport;
    text-transform: uppercase;
    letter-spacing: 0.01em;

    @include vp-767 {
      padding: 0 24px 0 36px;
    }
  }

  &__text {
    margin: 0;

    font-weight: 400;
    font-size: size(16px);
    line-height: 150%;

    @include vp-767 {
      padding: 0 24px 0 36px;
    }
  }

  &__buttons {
    position: relative;
    z-index: 1;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: size(150px);
    margin-top: size(40px);
    margin-left: size(7px);

    grid-row: 2/3;
    @include vp-767 {
      flex-direction: row;
      margin-top: 20px;
      margin-bottom: 47px;
      margin-left: 36px;
    }

    @include vp-374 {
      margin-left: 16px;
    }

    opacity: 0;

    .show & {
      opacity: 1;
    }
  }

  &__button {
    @include vp-767 {
      display: flex;
      align-items: center;
    }
    &:first-child {
      margin-bottom: size(51px);

      @include vp-767 {
        margin-right: 36px;
        margin-bottom: 0;
      }

      @include vp-374 {
        margin-right: 16px;
      }

      .btn__icon svg {
        @include vp-1023 {
          width: 25px;
          height: auto;
        }
      }
    }

    .btn__title {
      @include vp-767 {
        max-width: 75px;
        position: static;

        margin-left: 10px;

        font-size: 16px;
        line-height: 20px;
        text-align: left;

        transform: none;
      }
    }

    .btn__icon {
      width: size(77px);
      height: size(77px);

      @include vp-1023 {
        width: 54px;
        height: 54px;
      }
    }

    &--video {
      @include has-hover {
        .btn__icon-wrp {
          background-color: $color-alizarin;
        }

        .btn__title {
          color: $color-alizarin;
        }
      }
      svg {
        width: size(19px);
        height: size(16px);

        @include vp-1439 {
          width: 19px;
          height: auto;
        }

        @include vp-1023 {
          width: 10px;
        }

        @include vp-767 {
          width: 10px;
          height: auto;
        }
      }

      .btn__title {
        color: $color-dark-slate-blue;
      }

      .btn__icon-wrp {
        background-color: $color-dark-slate-blue;
      }
    }
  }

  &__tooltips {
    position: absolute;
    top: 0;
    right: 0;

    width: 100%;
    height: 100%;
  }

  &__tooltip {
    position: absolute;
    z-index: 15;

    display: flex;
    justify-content: center;
    align-items: center;
    width: size(28px);
    height: size(28px);

    background-color: $color-alizarin;
    border: none;
    border-radius: 50%;
    cursor: pointer;

    transition: background-color $trans-default;

    svg {
      min-width: size(5px);
      width: size(5px);
      height: size(16px);

      color: $color-default-white;

      @include vp-767 {
        width: 4px;
        min-width: 4px;
        height: auto;
      }
    }

    @include has-hover {
      background-color: $color-dark-slate-blue;
    }
  }

  &--1920 {
    //.timeline-slide__history-btn {
    //  margin-top: size(12px);
    //  margin-left: size(135px);
    //
    //  @include vp-767 {
    //    margin: 28px auto 0;
    //  }
    //}

    .timeline-slide__tooltip--1 {
      top: 12.4%;
      right: 39.5%;

      @include vp-767 {
        top: 56.7%;
        right: 56.7%;
      }
    }

    .timeline-slide__tooltip--2 {
      top: 15%;
      right: 9.3%;

      @include vp-767 {
        top: 81%;
        right: 83.3%;
      }
    }

    .timeline-slide__tooltip--3 {
      top: 43.4%;
      right: 7.8%;

      @include vp-767 {
        top: 74.4%;
        right: 40.8%;
      }
    }

    .timeline-slide__tooltip--4 {
      top: 72.9%;
      right: 37.1%;

      @include vp-767 {
        top: 14.9%;
        right: 11.1%;
      }
    }

    .timeline-slide__tooltip--5 {
      bottom: 31.4%;
      left: 2.8%;

      @include vp-767 {
        bottom: 53.4%;
        left: 18.8%;
      }
    }
  }

  &--1930 {
    .timeline-slide__history-btn {
      @include vp-767 {
        margin-top: 50px;
      }
    }

    .timeline-slide__tooltip--1 {
      top: 6.7%;
      right: 54.5%;

      @include vp-767 {
        top: -5.3%;
        right: 56.5%;
      }
    }

    .timeline-slide__tooltip--2 {
      top: 60%;
      right: 16.3%;

      @include vp-767 {
        top: 94%;
        right: 24.3%;
      }
    }

    .timeline-slide__tooltip--3 {
      right: 34.3%;
      bottom: 8.4%;

      @include vp-767 {
        right: 60.3%;
        bottom: 36.4%;
      }
    }

    .timeline-slide__tooltip--4 {
      bottom: 18.5%;
      left: 11.9%;

      @include vp-767 {
        bottom: auto;
        top: 10%;
        left: auto;
        right: 10%;
      }
    }
  }

  &--1940 {
    .timeline-slide__tooltip--1 {
      top: 42.5%;
      left: 20.8%;

      @include vp-767 {
        top: 8.7%;
        left: 78.5%;
      }
    }

    .timeline-slide__history-btn {
      @include vp-767 {
        margin-top: 50px;
      }
    }

    .timeline-slide__tooltip--2 {
      top: 59%;
      right: 47.3%;

      @include vp-767 {
        right: auto;
        left: 8%;
        top: 89%;
      }
    }

    .timeline-slide__tooltip--3 {
      right: 41.8%;
      top: 19.7%;

      @include vp-767 {
        right: 57.3%;
        top: -4%;
      }
    }

    .timeline-slide__tooltip--4 {
      bottom: 27.5%;
      right: 18.9%;

      @include vp-767 {
        bottom: 0;
        right: 30%;
      }
    }

    .timeline-slide__tooltip--5 {
      top: 4.5%;
      right: 25.1%;

      @include vp-767 {
        top: 58%;
        left: 32%;
        right: auto;
      }
    }
  }

  &--1950 {
    .timeline-slide__img {
      @include vp-767 {
        padding-top: 42px;
      }

      img {
        @include vp-767 {
          width: 94%;
          margin-left: auto;
        }
      }
    }

    .timeline-slide__history-btn {
      @include vp-767 {
        margin-top: 58px;
      }
    }

    .timeline-slide__tooltip--1 {
      top: 56.5%;
      left: 0;

      @include vp-767 {
        top: 6.3%;
        right: 16.5%;
        left: auto;
      }
    }

    .timeline-slide__tooltip--2 {
      top: 6%;
      right: 29.3%;

      @include vp-767 {
        top: 2%;
        right: auto;
        left: 10%;
      }
    }

    .timeline-slide__tooltip--3 {
      bottom: 19%;
      right: 22.3%;

      @include vp-767 {
        right: 83.3%;
        bottom: 41.4%;
      }
    }

    .timeline-slide__tooltip--4 {
      bottom: 25.5%;
      right: 53.9%;

      @include vp-767 {
        right: 5%;
        bottom: 34.5% ;
      }
    }
  }

  &--1960 {
    .timeline-slide__img {
      @include vp-767 {
        padding-top: 10px;
      }

      img {
        @include vp-767 {
          width: 96%;
          margin-left: auto;
        }
      }
    }

    .timeline-slide__history-btn {
      @include vp-767 {
        margin-top: 58px;
      }
    }

    .timeline-slide__tooltip--1 {
      bottom: 12%;
      left: 11%;

      @include vp-767 {
        left: 15%;
        top: 45.7%;
      }
    }

    .timeline-slide__tooltip--2 {
      top: 27%;
      right: 45.3%;

      @include vp-767 {
        top: 16%;
        right: 61.3%;
      }
    }

    .timeline-slide__tooltip--3 {
      bottom: 34%;
      right: 45.3%;

      @include vp-767 {
        right: 24.3%;
        bottom: 8.4%;
      }
    }

    .timeline-slide__tooltip--4 {
      bottom: 16.5%;
      right: 19.4%;

      @include vp-767 {
        bottom: auto;
        top: 36%;
        left: auto;
        right: 38%;
      }
    }
  }

  &--1970 {
    .timeline-slide__img {
    }

    .timeline-slide__history-btn {
      @include vp-767 {
        margin-top: -60px;
      }
    }

    .timeline-slide__tooltip--1 {
      bottom: 10%;
      left: 43%;

      @include vp-767 {
        left: 39%;
        top: 50.4%;
      }
    }

    .timeline-slide__tooltip--2 {
      top: 17%;
      right: 28.3%;

      @include vp-767 {
        top: 29.3%;
        right: 19.3%;
      }
    }

    .timeline-slide__tooltip--3 {
      bottom: 53%;
      left: 17.3%;

      @include vp-767 {
        left: 62.3%;
        bottom: 50.4%;
      }
    }

    .timeline-slide__tooltip--4 {
      bottom: 16.5%;
      right: 19.4%;

      @include vp-767 {
        bottom: 31.5%;
        right: 78.7%;
      }
    }

    .timeline-slide__tooltip--5 {
      top: 27.5%;
      right: 57.4%;

      @include vp-767 {
        top: 17%;
        right: 56%;
      }
    }
  }

  &--1980 {
    .timeline-slide__img {
    }

    .timeline-slide__history-btn {
      @include vp-767 {
        margin-top: 0;
      }
    }

    .timeline-slide__tooltip--1 {
      bottom: 20%;
      left: 4%;

      @include vp-767 {
        left: 48%;
        bottom: 8.4%;
      }
    }

    .timeline-slide__tooltip--2 {
      bottom: 71%;
      left: 38.3%;

      @include vp-767 {
        top: 21%;
        left: 33.3%;
      }
    }

    .timeline-slide__tooltip--3 {
      top: 10%;
      right: 30%;

      @include vp-767 {
        left: 9.3%;
        top: 43.4%;
      }
    }

    .timeline-slide__tooltip--4 {
      bottom: 19.5%;
      right: 14.4%;

      @include vp-767 {
        bottom: 61%;
        right: 23.7%;
      }
    }
  }

  &--2000 {
    .timeline-slide__img {
    }

    .timeline-slide__history-btn {
      @include vp-767 {
        margin-top: 40px;
      }
    }

    .timeline-slide__tooltip--1 {
      bottom: 25%;
      left: 5%;

      @include vp-767 {
        left: 34%;
        bottom: 48.4%;
      }
    }

    .timeline-slide__tooltip--2 {
      top: 25%;
      right: 57.3%;

      @include vp-767 {
        top: 3.6%;
        right: 28.7%;
      }
    }

    .timeline-slide__tooltip--3 {
      top: 82%;
      right: 49%;

      @include vp-767 {
        left: 15.6%;
        top: auto;
        bottom: 8.4%;
      }
    }

    .timeline-slide__tooltip--4 {
      top: 19.5%;
      right: 11%;

      @include vp-767 {
        top: 26%;
        right: 23.7%;
      }
    }

    .timeline-slide__tooltip--5 {
      bottom: 43.5%;
      right: 9.4%;

      @include vp-767 {
        bottom: 49%;
        right: 15.7%;
      }
    }
  }

  &--1990 {
    .timeline-slide__img {
    }

    .timeline-slide__history-btn {
      @include vp-767 {
        margin-top: 60px;
      }
    }

    .timeline-slide__tooltip--1 {
      bottom: 25%;
      left: 18%;

      @include vp-767 {
        left: 13%;
        bottom: 42.4%;
      }
    }

    .timeline-slide__tooltip--2 {
        top: 23%;
        right: 26.3%;

      @include vp-767 {
        top: 4%;
        left: 32.3%;
      }
    }

    .timeline-slide__tooltip--3 {
      top: 59%;
      right: 49%;

      @include vp-767 {
        left: 37.6%;
        top: auto;
        bottom: 10.4%;
      }
    }

    .timeline-slide__tooltip--4 {
      bottom: 35.5%;
      right: 23.4%;

      @include vp-767 {
        bottom: 63%;
        right: 7.7%;
      }
    }
  }

  &--2010 {
    .timeline-slide__img {
    }

    .timeline-slide__history-btn {
      @include vp-767 {
        margin-top: 0;
      }
    }

    .timeline-slide__tooltip--1 {
      bottom: 25%;
      left: 5%;

      @include vp-767 {
        left: 9%;
        bottom: 47.4%;
      }
    }

    .timeline-slide__tooltip--2 {
      top: 61%;
      right: 52.3%;

      @include vp-767 {
        top: 28.6%;
        right: 16.7%;
      }
    }

    .timeline-slide__tooltip--3 {
      top: 20%;
      right: 44%;

      @include vp-767 {
        left: 37.6%;
        top: 6.1%;
      }
    }

    .timeline-slide__tooltip--4 {
      bottom: 21.5%;
      right: 29%;

      @include vp-767 {
        bottom: 15%;
        right: 44.7%;
      }
    }
  }

  &__hover-block {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
  }

  &__hover-svg {
    width: 100%;
    height: 100%;

    @include vp-767 {
      display: none;
    }
  }

  &__hover-item {
    position: relative;
    outline: 0;
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.1s ease-in-out;

    fill: $color-default-white;

    &:hover,
    &:focus {
      z-index: 1000;
      opacity: 0;
    }
  }

  &__hover-text {
    position: relative;
    z-index: 100;
    opacity: 0;

    transition: opacity $trans-default;

    pointer-events: none;

    &.is-active {
      opacity: 1;
    }
  }
}

.inner-svg-text {
  font-family: $ff-mossport;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: 0.01em;
  text-transform: uppercase;
}

.test {
  position: absolute;
  left: -10000px;
  top: -10000px;
  display: inline;
  opacity: 0;
  z-index: 9999;
  font-family: $ff-mossport;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: 0.01em;
}
