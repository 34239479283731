.header {
  position: fixed;
  top: 0;
  z-index: 199;

  width: 100%;

  transition: background-color $trans-default;
  height: size(90px);

  @include vp-767 {
    height: 70px;
  }

  &.is-fixed {
    background-color: rgba(255, 255, 255, 0.95);
  }

  &__logo {
    position: absolute;
    top: size(20px);
    left: size(34px);

    @include vp-1439 {
      top: 20px;
      left: 34px;
    }

    @include vp-767 {
      top: 18px;
      left: 24px;
    }

    svg {
      width: size(179px);
      height: size(50px);

      @include vp-1439 {
        width: 179px;
        height: 50px;
      }
      @include vp-767 {
        width: 116px;
        height: auto;
      }
    }
  }

  &--empty {
    .main-nav {
      display: none;
    }
  }
}
