.modal-timeline {
  padding: size(72px) size(80px) size(60px);

  box-shadow: 0 0 30px rgba(177, 182, 187, 0.15);

  @include vp-767 {
    padding: 92px 8px 53px;
  }

  &__title {
    margin: 0 size(20px) size(40px);

    font-style: normal;
    font-weight: 400;
    font-size: size(56px);
    line-height: 100%;
    font-family: $ff-mossport;
    text-align: center;
    color: $color-alizarin;
    text-transform: uppercase;
    letter-spacing: 0.01em;

    @include vp-767 {
      margin-bottom: 40px;

      font-size: 48px;
      line-height: 49px;
    }
  }

  &__desc {
    margin: 0 size(20px);

    p {
      margin: 0 0 size(40px);

      font-weight: 400;
      font-size: size(14px);
      line-height: 171%;

      &:last-child {
        margin-bottom: 0;
      }

      @include vp-767 {
        margin-bottom: 24px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    h3 {
      margin: size(60px) 0 size(24px);

      font-weight: 400;
      font-size: size(28px);
      line-height: 121%;
      font-family: $ff-mossport;
      text-transform: uppercase;
      letter-spacing: 0.01em;
    }
  }



  &__controls {
    display: flex;
    justify-content: space-between;
    margin-top: size(40px);
    margin-right: size(20px);
    margin-left: size(20px);

    @include vp-767 {
      margin-right: 16px;
      margin-left: 16px;
    }
  }

  &__control {
    display: flex;
    justify-content: space-between;
    width: calc(50% - #{size(15px)});
    height: size(61px);
    padding: size(20px) size(30px);

    font-weight: 400;
    font-size: size(22px);
    line-height: 109%;
    font-family: $ff-mossport;
    color: $color-alizarin;
    text-transform: uppercase;
    letter-spacing: 0.01em;

    background-color: transparent;
    border: 1px solid $color-alizarin;
    cursor: pointer;

    transition: opacity $trans-default;

    @include vp-374 {
      font-size: 16px;
    }

    svg {
      flex-shrink: 0;
      width: size(16px);
      height: size(16px);
    }

    @include has-hover {
      opacity: 0.7;
    }

    @include vp-767 {
      align-items: center;
      width: calc(50% - 10px);
      padding: 11px 20px 8px;
    }

    &--next {
      color: $color-default-white;

      background-color: $color-alizarin;

      @include vp-767 {
        text-align: left;
      }

      span {
        @include vp-767 {
          margin-right: 15px;
        }
      }

      svg {
        transform: rotate(180deg);
      }
    }

    &--prev {
      flex-direction: row-reverse;

      @include vp-767 {
        text-align: left;
      }

      span {
        @include vp-767 {
          margin-left: 15px;
        }
      }
    }

    svg {
      color: inherit;
    }
  }

  &__form {
    display: none;
    margin-top: size(59px);
    margin-right: size(20px);
    margin-left: size(20px);
    padding-top: size(72px);

    border-top: 1px solid #d4d4d4;

    @include vp-767 {
      margin-top: 60px;
      margin-right: 16px;
      margin-left: 16px;
      padding-top: 48px;
    }
  }

  &__author {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    gap: size(10px);
    margin-right: size(20px);
    margin-bottom: size(21px);
    margin-left: size(20px);

    @include vp-767 {
      margin-right: 16px;
      margin-bottom: 24px;
      margin-left: 16px;
    }

    img {
      width: size(24px);
      height: size(24px);

      border-radius: 50%;

      object-fit: cover;

      margin-right: size(13px);
    }

    &--mb {
      margin-bottom: 23px;

      @include vp-767 {
        margin-bottom: 14px;
      }
    }
  }

  &__insignia,
  &__author-name {
    padding-top: size(3px);

    font-weight: 700;
    font-size: size(14px);
    line-height: 200%;
  }

  &__insignia {
    font-style: italic;
    padding: 0;
    margin: 0 size(20px) size(41px);
  }

  &__author-achievements {
    max-width: size(517px);
    padding: size(7px) size(7px) size(10px);
    border: size(1px) solid #F3F4F4;
    border-radius: size(6px);
    font-weight: 400;
    font-size: size(16px);
    line-height: size(24px);
    color: #000000;
    margin: 0 size(20px) size(22px);

    @include vp-767 {
      line-height: 22px;
      margin-right: 16px;
      margin-bottom: 13px;
      margin-left: 16px;
    }
  }

  &__history-img {
    width: 100%;
    margin-bottom: size(40px);

    img {
      margin: 0 auto;
      width: auto;
      height: 100%;
      max-height: size(438px);
      object-fit: cover;
    }
  }

  &__inner-slider-wrap {
    margin: size(47px) size(20px) 0;
  }

  &__inner-slider {
    position: relative;
  }

  &__thumbs {
    padding-top: size(15px);
  }

  &__btns {
    display: flex;
    justify-content: center;
    margin-top: size(115px);

    @include vp-767 {
      margin-top: size(86px);
    }
  }

  &__tooltip-btn {

    .btn__title {
      top: 0;
      transform: none;
    }

    @include vp-767 {
      transform: translateX(-50px);

      .btn__icon {
        width: 91px;
        height: 91px;
      }

      .btn__title {
        left: calc(100% + 25px);
      }
    }
  }

  .timeline-form {
    margin: size(60px) size(20px) 0;
    padding-top: size(60px);
    border-top: 1px solid rgba($color-nero, 0.2);
  }

  .timeline-form__title {
    @include vp-767 {
      max-width: 280px;
      margin: 0 auto 18px;
    }
  }

  .timeline-form__desc {
    @include vp-767 {
      margin-bottom: 41px;
    }
  }

  .timeline-form__banner {
    svg {
      @include vp-767 {
        width: 93px;
        min-width: 93px;
      }
    }
  }

  .timeline-form__banner {
    @include vp-767 {
      margin: 0 0 46px;
    }
  }

  .timeline-form__banner-text {
    @include vp-767 {
      font-size: 14px;
      line-height: 18px;
    }
  }

  .timeline-form__btn {
    @include vp-767 {
      margin-left: 47px;
      margin-bottom: 59px;
    }
  }

  &__radio {
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    font-weight: 400;
    font-size: size(14px);
    line-height: 200%;
    color: #949494;
  }
}

.modal-timeline-slider__slide {
  position: relative;
  padding-top: 71.4%;

  @include vp-767 {
    padding-top: 72.8%;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.modal-timeline-slider__button {
  cursor: pointer;
  padding: 0;
  background: $color-transparent;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  width: size(40px);
  height: size(40px);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  @include vp-767 {
    width: 36px;
    height: 36px;
  }

  svg {
    width: 100%;
    height: 100%;
  }

  &--prev {
    left: size(-20px);

    @include vp-767 {
      left: -16px;
    }
  }

  &--next {
    right: size(-20px);

    @include vp-767 {
      right: -16px;
    }
  }
}

.modal-timeline-thumbs__slide {
  width: size(49px);
  height: size(35px);
  opacity: 0.4;
  transition: opacity $trans-default;

  &.swiper-slide-thumb-active {
    opacity: 1;
  }

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
