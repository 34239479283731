.share-aside {
  grid-column: col-start 11 / span 2;
  grid-row: 2;
  max-width: size(226px);
  margin-right: 5px;
  position: relative;

  &__title {
    font-family: $ff-mossport;
    font-weight: 400;
    font-size: size(32px);
    line-height: 112%;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    color: $color-alizarin;
    margin: 0 0 size(38px) 0;

    @include vp-767 {
      margin-bottom: 13px;
    }
  }

  &__text {
    font-weight: 400;
    font-size: size(14px);
    line-height: 100%;
    color: $color-alizarin;
    margin: 0 0 size(14px);
  }

  @include vp-1279 {
    max-width: size(784px);
    width: 100%;
    padding: 0 20px;
    margin-top: 60px;

    .banner {
      display: none;
    }
  }

  @include vp-767 {
    margin-top: 35px;
    padding:  0 28px;
  }

  .share-form__sub-header {
    text-align: left;
  }

  .swiper-slide {
    display: flex;
    flex-wrap: wrap;
    row-gap: size(14px);
    align-items: flex-start;
    height: auto;
    padding: 0 size(13px) 0 size(16px);

    .custom-radio {
      width: 33.33%;

      @include vp-767 {
        min-width: size(95px);
        margin-right: size(29px);
      }
    }

    @include vp-767 {
      padding: 0;
      flex-wrap: nowrap;
      width: auto;
    }
  }

  .swiper-button-next,
  .swiper-button-prev {
    height: size(42px);
    width: size(42px);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $color-alizarin;
    //margin-bottom: size(93px);
    margin-top: 0;
    top: size(280px);
    transform: none;
    left: size(-25px);

    &:after {
      display: none;
    }

    svg {
      color: $color-default-white;
    }

    &.swiper-button-disabled {
      background-color: transparent;
      opacity: 0.5;
      border: 1px solid $color-nero;

      svg {
        color: $color-nero;
      }
    }

    @include vp-767 {
      display: none;
    }
  }

  .swiper-button-next {
    left: auto;
    right: size(-36px);

    svg {
      transform: rotate(180deg);
    }
  }

  .swiper {
    @include vp-767 {
      padding-left: 28px;
    }
  }
}
