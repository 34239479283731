.banner {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  border-radius: 50%;
  position: relative;
  //grid-template-columns: size(116px) 1fr;
  //grid-template-rows: auto 1fr;
  padding: size(68px) size(39px) 0 size(39px);
  //min-height: size(114px);
  //column-gap: size(13px);
  margin: size(7px) auto size(38px);
  width: size(208px);
  height: size(208px);

  // margin: 0 size(107px) size(29px);

  color: $color-default-white;

  background-color: $color-dark-slate-blue;

  &--mobile {
    display: none;
  }

  @include vp-1279 {
    width: 100%;
    border-radius: 0;
    flex-direction: row;
    padding: 20px;
    height: auto;
    justify-content: space-between;

    &--mobile {
      display: flex;
    }
  }

  @include vp-767 {
    overflow: hidden;
    align-items: center;
    padding: 15px 10px 0 10px;
  }

  &__icon-prize {
    //grid-row: 1/3;
    min-width: size(96px);
    //margin-top: size(-30px);
    height: auto;
    position: absolute;
    top: size(-42px);

    @include vp-1279 {
      position: static;
      display: none;
    }

    @include vp-767 {
      position: relative;
      min-width: 100px;
      top: auto;
      bottom: -5px;
      margin-bottom: -10px;
      left: 6px;
    }

    &--mob {
      display: none;

      @include vp-1279 {
        display: block;
        margin-top: 0;
        padding-bottom: 0;
      }
    }
  }

  &__text {
    margin: 0;

    font-weight: 400;
    font-size: size(12px);
    line-height: 142%;
    text-transform: uppercase;

    @include vp-1279 {
      margin-top: 0;
    }

    @include vp-767 {
      margin-top: 0;
      margin-bottom: 12px;
      text-transform: none;
      font-size: 14px;
      text-align: left;
      margin-left: 13px;
    }
  }

  &__icon {
    margin-top: size(15px);
    height: size(28px);
    width: size(28px);
    border-radius: 50%;
    background-color: #4B43A8;
    padding: size(6px);
    cursor: pointer;
    transition: background-color $trans-default;

    @include has-hover {
      background-color: $color-alizarin;
    }

    @include vp-1279 {
      margin-top: 0;
    }

    @include vp-767 {
      display: none;
    }
  }

  &__link {
    grid-column: 2/3;
    grid-row: 2/3;

    font-weight: 400;
    font-size: size(12px);
    line-height: size(22px);

    text-decoration: underline;

    color: $color-default-white;

    opacity: 0.7;

    transition: opacity $trans-default;

    @include has-hover {
      opacity: 0.4;
    }
  }
}
