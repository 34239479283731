// @font-face {
//   font-style: normal;
//   font-weight: 400;
//   font-family: "Rouble";

//   font-display: swap;
//   src:
//     url("../fonts/rouble.woff2") format("woff2"),
//     url("../fonts/rouble.woff") format("woff");
// }

@font-face {
  font-style: normal;
  font-weight: 400;
  font-family: "Mossport";

  font-display: swap;
  src:
    url("https://100.moscow.sport/fonts/mossport.woff2") format("woff2"),
    url("https://100.moscow.sport/fonts/mossport.woff") format("woff");
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-family: "Gotham Pro";

  font-display: swap;
  src:
    url("../fonts/gothampro.woff2") format("woff2"),
    url("../fonts/gothampro.woff") format("woff");
}

@font-face {
  font-style: normal;
  font-weight: 500;
  font-family: "Gotham Pro";

  font-display: swap;
  src:
    url("../fonts/gothampro-medium.woff2") format("woff2"),
    url("../fonts/gothampro-medium.woff") format("woff");
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-family: "Gotham Pro";

  font-display: swap;
  src:
    url("../fonts/gothampro-bold.woff2") format("woff2"),
    url("../fonts/gothampro-bold.woff") format("woff");
}

