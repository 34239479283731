.timeline-form {
  &__title {
    margin: 0 0 size(27px);

    font-weight: 400;
    font-size: size(36px);
    line-height: 100%;
    font-family: $ff-mossport;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.01em;

    @include vp-767 {
      font-size: 28px;
      line-height: 34px;
    }
  }

  &__desc {
    margin: 0 0 size(40px);

    font-weight: 400;
    font-size: size(16px);
    line-height: 150%;
    text-align: center;

    @include vp-767 {
      margin-bottom: 48px;
    }
  }

  &__banner {
    display: flex;
    align-items: center;
    margin-right: size(76px);
    margin-bottom: size(36px);
    margin-left: size(74px);
    padding: 0 size(30px) 0 size(32px);

    color: $color-default-white;

    background-color: $color-dark-slate-blue;

    @include vp-767 {
      position: relative;

      margin: 0 0 32px;
      padding: 15px 5px 15px 124px;
    }

    svg {
      min-width: size(98px);
      margin-top: size(13px);
      height: auto;

      @include vp-767 {
        position: absolute;
        bottom: 0;
        left: 14px;

        min-width: 110px;
      }
    }
  }

  &__banner-text {
    margin: 0 0 0 size(26px);

    font-weight: 400;
    font-size: size(14px);
    line-height: 157%;

    @include vp-767 {
      margin-left: 0;
    }
  }

  form {
    margin-right: size(76px);
    margin-left: size(74px);

    @include vp-767 {
      margin: 0;
    }
  }

  &__input {
    margin-bottom: size(15px);

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &__btn {
    margin-top: size(15px);
    margin-bottom: size(67px);
    margin-left: 30%;

    @include vp-767 {
      margin-bottom: 52px;
      margin-left: 20px;
    }

    .btn__icon {
      @include vp-767 {
        width: 91px;
        height: 91px;
      }
    }

    .btn__title {
      top: size(20px);

      padding-left: size(27px);

      @include vp-767 {
        top: 0;
        left: 100%;

        text-align: left;

        transform: none;
      }
    }

    .btn__subtext {
      bottom: 0;

      margin-left: size(27px);
    }
  }

  &__bottom-text {
    margin: 0;

    font-size: size(16px);
    line-height: 150%;
    text-align: center;

    a {
      color: $color-alizarin;

      transition: color $trans-default;

      @include has-hover {
        color: $color-dark-slate-blue;
      }
    }
  }

  &__note {
    font-weight: 400;
    font-size: size(14px);
    line-height: 114%;

    color: $color-dark-slate-blue;

    margin: size(5px) 0 size(19px);
  }

  &__inner {
    .is-send & {
      display: none;
    }
  }

  &__message {
    display: none;

    flex-direction: column;
    align-items: center;
    justify-content: center;

    height: 100%;

    @include vp-1023 {
      padding: 60px 0;
    }

    .is-send & {
      display: flex;
    }
  }

  &__message-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: size(91px);
    height: size(91px);
    margin-bottom: size(30px);
    border-radius: 50%;
    background-color: $color-alizarin;
  }

  &__message-header {
    font-family: $ff-mossport;
    font-weight: 400;
    font-size: size(36px);
    line-height: 125%;

    text-align: center;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    margin: 0 0 size(40px);
  }

  &__message-text {
    margin: 0;
    font-size: size(14px);
    line-height: 171%;

    text-align: center;
    max-width: size(300px);
  }

  &__prompt {
    font-weight: 400;
    font-size: size(14px);
    line-height: size(16px);
    text-align: center;
    color: rgba(40, 40, 40, 0.5);
    margin-top: 0;

    @include vp-767 {
      line-height: 20px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}
