.btn {
  $root: &;

  position: relative;
  display: inline-flex;
  margin: 0;
  padding: 0;

  font-family: $ff-mossport;
  font-size: size(22px);
  line-height: 109%;
  color: $color-alizarin;
  letter-spacing: 0.01em;
  text-transform: uppercase;

  background-color: $color-transparent;
  border: none;
  outline: none;
  cursor: pointer;

  transition: background-color $trans-default, color $trans-default;

  @include vp-1439 {
    font-size: 22px;
  }

  @include vp-767 {
    font-size: 20px;
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;

    // &:focus,
    // &:hover {
    //   background-color: $color-transparent;
    // }
  }

  &:disabled {
    opacity: 0.5;

    pointer-events: none;
  }

  @include has-hover {
    .btn__icon-wrp {
      background-color: $color-dark-slate-blue;
    }

    .btn__title {
      color: $color-dark-slate-blue;
    }
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: size(91px);
    height: size(91px);
    border-radius: 50%;
    overflow: hidden;

    @include vp-1439 {
      width: 91px;
      height: 91px;
    }

    @include vp-767 {
      width: 68px;
      height: 68px;

      background-color: transparent;
      -webkit-tap-highlight-color: transparent;
    }
  }

  &__icon-wrp {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: $color-alizarin;
    border-radius: 50%;
    transition: all $trans-default;

    .show & {
      animation: scale-100 1s ease-in-out;
    }

    svg {
      flex-shrink: 0;
      width: size(45px);
      height: size(44px);
      fill: $color-transparent;

      @include vp-1439 {
        width: 45px;
        height: 44px;
      }

      @include vp-767 {
        width: 34px;
        height: 34px;
      }
    }
  }

  &__title {
    position: absolute;
    top: 100%;
    left: 100%;
    // align-self: flex-end;
    width: max-content;
    padding: size(8px) 0 0 size(6px);
    text-align: left;
    transform: translateY(-50%);
    transition: all $trans-default;

    .show & {
      animation: maskRight-50 2s linear;
    }


    @include vp-1439 {
      padding: 8px 0 0 6px;
    }

    @include vp-767 {
      top: calc(100% + 12px);
      left: 50%;
      padding: 0;
      text-align: center;
      transform: translateX(-50%) translateY(0);
    }
  }

  &__subtext {
    width: size(134px);
    position: absolute;
    bottom: 100%;
    left: 100%;
    font-weight: 400;
    font-size: size(12px);
    line-height: 133%;
    font-family: $ff-gotham;
    color: $color-nero;
    text-transform: none;
  }

  &--play {
    #{$root}__icon-wrp {
      svg {
        width: size(12px);
        height: size(17px);

        @include vp-1439 {
          width: 12px;
          height: 17px;
        }
      }
    }
  }
}

.btn-square {
  appearance: none;
  border: none;
  cursor: pointer;
  padding: size(15px) size(17px);
  font-family: $ff-mossport;
  font-weight: 400;
  font-size: size(22px);
  line-height: 120%;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: $color-default-white;
  background-color: $color-alizarin;
  opacity: 1;
  transition: opacity $trans-default;

  @include has-hover {
    opacity: 0.7;
  }

  &.is-hidden {
    display: none;
  }
}

.back-btn {
  appearance: none;
  border: none;
  cursor: pointer;
  padding: size(12px) size(27px);
  font-family: $ff-mossport;
  font-weight: 400;
  font-size: size(22px);
  line-height: 109%;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: $color-alizarin;
  background-color: $color-default-white;
  opacity: 1;
  transition: opacity $trans-default;
  box-shadow: 0 0 30px rgba(177, 182, 187, 0.15);

  svg {
    margin-right: size(15px);
  }

  @include has-hover {
    opacity: 0.7;
  }

}
