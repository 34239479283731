.history-btn {
  display: flex;
  align-items: center;

  max-height: size(70px);
  padding: 0 size(19px) 0 size(19px);

  background: $color-default-white;
  box-shadow: 0px 9px 25px rgba(68, 71, 74, 0.12);
  border-radius: 100px;

  height: size(70px);

  @include has-hover {
    .history-btn__svg {
      opacity: 0.7;
    }
  }

  &__images {
    display: flex;

    flex-shrink: 0;

    @include vp-767 {
      margin-right: 8px;
    }

    img {
      width: size(34px);
      height: size(34px);
      border-radius: 50%;
      border: 3px solid $color-default-white;

      object-fit: cover;
    }

    img:not(:first-child) {
      margin-left: -#{size(14px)};
    }
  }

  &__desc {
    margin-right: 0;
    margin-left: size(12px);

    @include vp-767 {
      margin-right: 10px;
    }
  }

  &__text {
    margin: 0 0 size(5px) 0;
    font-weight: 700;
    white-space: nowrap;
    font-size: size(14px);
    line-height: 100%;
  }

  &__quantity {
    margin: 0;
    font-weight: 400;
    font-size: size(12px);
    line-height: 117%;

    white-space: nowrap;

    color: $color-alizarin;
  }

  &__svg {
    width: size(28px);
    height: size(28px);

    margin-left: size(60px);
    flex-shrink: 0;
    transition: opacity $trans-default;
    color: $color-alizarin;
  }
}
