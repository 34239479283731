// используйте для скрытия нежелательных анимаций-скачков при поворотах экрана
@keyframes disableBreakpointAnimation {
  0% {
    visibility: hidden;
    opacity: 0;
  }

  100% {
    visibility: hidden;
    opacity: 0;
  }
}

@keyframes scale {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes scale-100 {
  0% {
    transform: scale(0);
  }

  50% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes decrease-100 {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(0);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes maskRight-50 {
  0% {
    clip-path: polygon(0 0, 0 0, 0 100%, 0 100%);
  }

  50% {
    clip-path: polygon(0 0, 0 0, 0 100%, 0 100%);
  }

  100% {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
}


@keyframes maskLeft-hide {
  0% {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }

  50% {
    clip-path: polygon(0 0, 0 0, 0 100%, 0 100%);
  }

  100% {
    clip-path: polygon(0 0, 0 0, 0 100%, 0 100%);
  }
}

@keyframes fade-in-top-15 {
  0% {
    opacity: 0;
    transform: translateY(15%);
  }

  50% {
    opacity: 0;
    transform: translateY(15%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes in-top-100 {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }

  // 50% {
  //   transform: translateY(100%);
  // }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes doc-intro-to-left {
  0% {
    transform: translate(50%, calc(100% + 312px));
  }

  50% {
    transform: translate(50%, calc(100% + 106px));
  }

  100% {
    transform: translate(0, 0);
  }
}

@keyframes doc-intro-to-right {
  0% {
    transform: translate(-90%, calc(100% + 312px));
  }

  50% {
    transform: translate(-90%, calc(100% + 106px));
  }

  100% {
    transform: translate(0, 0);
  }
}

@keyframes up-scale {
  0% {
    opacity: 0;
    transform: translateY(50%) scale(0);
  }

  // 50% {
  //   opacity: 0;
  //   transform: translateY(15%);
  // }

  100% {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}

@keyframes maskUp {
  0% {
    clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0 100%);
  }

  // 50% {
  //   opacity: 0;
  //   transform: translateY(15%);
  // }

  100% {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
