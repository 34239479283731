.modal-video {
  $b: #{&};
  display: flex;
  width: 100%;
  background-color: $color-default-black;
  position: relative;
  padding-top: 56.25%;

  &__iframe img {
    object-fit: cover;
    width: 100%;
  }

  iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    //aspect-ratio: 16/9; not working in safari 13
    border: none;
    background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
  }

  &__title {
    font-family: $ff-mossport;
    font-weight: 400;
    font-size: size(70px);
    line-height: 91%;
    text-align: center;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    color: $color-alizarin;
    margin-top: 57px;
    margin-bottom: 12px;

    @include vp-767 {
      font-size: size(36px);
    }
  }

  &__text {
    margin: 0;
    font-weight: 400;
    font-size: size(16px);
    line-height: 150%;
    text-align: center;
    color: $color-default-black;
  }

  &__description {
    background-color: $color-dark-slate-blue;
    display: flex;
    flex-direction: column;
    padding: 45px 42px 35px 80px;

    @include vp-767 {
      padding: 21px 15px 30px 23px;
    }
  }

  &__wrapper {
    display: flex;

    @include vp-767 {
      flex-direction: column;
    }
  }

  &__btn.btn {
    opacity: 1;
    transition: opacity $trans-default;
    height: size(133px);

    @include has-hover {
      opacity: 0.7;
      &:not(.focus-visible) .btn__title {
        color: $color-default-white;
      }

      &:not(.focus-visible) .btn__icon-wrp {
        background-color: $color-alizarin;
      }
    }

    @include vp-767 {
      width: 150px;
      margin-right: 50px;
      margin-left: auto;
      height: 100px;
      margin-top: 20px;
    }

    .btn__icon {
      width: size(91px);
      height: size(91px);

      @include vp-767 {
        width: 47px;
        height: 47px;
        margin-right: 3px;
      }
    }

    .btn__title {
      transform: translateY(calc(-50% - 7px));
      position: relative;
      left: 0;
      color: $color-default-white;

      @include vp-767 {
        font-size: 22px;
        line-height: 24px;
        text-align: left;
        bottom: -35px;
        top: auto;
        transform: none;
      }
    }
  }

  &__img svg {
    width: 100%;
    height: auto;
    margin: 0 auto;
  }
}
