.sequence-text {
  &__title {
    margin: 0 0 size(31px);

    font-size: size(48px);
    line-height: size(56px);
    font-family: $ff-mossport;
    text-transform: uppercase;
    letter-spacing: 0.01em;

    @include vp-1439 {
      margin-bottom: 31px;

      font-size: 48px;
      line-height: 56px;
    }

    @include vp-1024 {
      margin-bottom: 26px;

      font-size: 31px;
      line-height: 36px;
    }
  }

  &__text {
    margin: 0 0 size(12px);

    font-size: size(10px);
    line-height: size(15px);

    @include vp-1439 {
      margin-bottom: 12px;

      font-size: 10px;
      line-height: 15px;
    }
  }
}
