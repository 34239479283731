.stars-stories {
  padding-top: size(113px);
  background-image: url("../img/content/timeline/slide-bg.png");
  background-size: cover;
  position: relative;

  @include vp-767 {
    padding-top: size(123px);
  }

  &__bg {
    position: fixed;
    left: 0;
    top: 149px;

    &:nth-child(2) {
      top: 229px;
      left: auto;
      right: 0;
    }

    @include vp-1023 {
      left: -10%;

      &:nth-child(2) {
        right: -10%;
      }
    }

    @include vp-767 {
      display: none;
    }
  }

  &__wrapper {
    position: relative;
    z-index: 1;
  }

  &__list {
    margin: 0;
    padding: 0;
    list-style: none;
    margin-bottom: size(89px);

    @include vp-767 {
      margin-bottom: size(45px);
    }
  }

  &__title {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: $ff-mossport;
    font-weight: 400;
    font-size: size(109px);
    line-height: 100%;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    color: $color-alizarin;
    margin: 0 0 size(82px) 0;

    //svg {
    //  margin-right: 12px;
    //  height: size(109px);
    //  width: size(124px);
    //}

    @include vp-767 {
      font-size: size(36px);
      margin-bottom: size(40px);

      svg {
        //margin-right: 12px;
        height: size(62px);
        width: size(189px);
      }
    }
  }

  &__sub-title {
    font-family: $ff-mossport;
    font-weight: 400;
    font-size: size(36px);
    line-height: 106%;
    text-align: center;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    color: $color-nero;
    margin: 0 0 size(45px) 0;

    @include vp-767 {
      font-size: size(22px);
      margin-bottom: size(20px);
    }
  }

  &__story {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: size(31px);

    @include vp-767 {
      margin-bottom: size(20px);
    }
  }

  &__name,
  &__dignity,
  &__caption {
    text-align: center;
  }

  &__name {
    font-family: $ff-mossport;
    font-weight: 400;
    font-size: size(36px);
    line-height: 106%;
    text-align: center;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    color: $color-alizarin;
    margin: 0 0 6px 0;

    @include vp-767 {
      font-size: size(22px);
    }
  }

  &__dignity,
  &__caption {
    font-weight: 400;
    font-size: size(16px);
    line-height: 150%;
    color: $color-default-black;
    margin: 0;
  }

  &__caption {
    font-weight: 700;
    position: relative;
    display: inline;
    //text-decoration: underline;
    opacity: 1;
    transition: opacity $trans-default;

    &:after {
      content: "";
      display: block;
      position: absolute;
      height: 1px;
      width: 100%;
      bottom: 3px;
      left: 0;
      background-color: $color-default-black;
    }

    @include has-hover {
      opacity: 0.6;
    }

    @include vp-1439 {
      &:after {
        bottom: 3px;
      }
    }

    @include vp-767 {
      text-decoration: underline;

      &:after {
        display: none;
      }
    }
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: size(151px);

    @include vp-767 {
      margin-top: size(40px);
      margin-bottom: size(100px);
    }
  }

  &__btn {
    .btn__icon {
      height: size(77px);
      width: size(77px);
    }

    .btn__title {
      padding-left: size(13px);
      padding-top: 0;
      max-width: size(125px);

      @include vp-767 {
        left: 100%;
        transform: translateY(0);
        text-align: left;
      }
    }

    &--blue {
      color: $color-dark-slate-blue;
      margin-bottom: size(52px);

      .btn__icon-wrp {
        background-color: $color-dark-slate-blue;
      }

      @include has-hover {
        .btn__title {
          color: $color-alizarin;
        }

        .btn__icon-wrp {
          background-color: $color-alizarin;
        }
      }

      @include vp-767 {
        margin-bottom: size(80px);
      }
    }
  }

  &__block:last-of-type {
    .stars-stories__list {
      margin-bottom: size(80px);

      @include vp-767 {
        margin-bottom: size(70px);
      }

    }
  }

  &__navigation {
    margin-bottom: size(51px);

    @include vp-767 {
      margin-bottom: 46px;
    }
  }
}
