.modal-copyright {
  min-width: size(680px);
  padding: size(75px) size(100px) size(80px);

  @include vp-1439 {
    min-width: 680px;
    padding: 75px 100px 80px;
  }

  @include vp-767 {
    min-width: 100%;
    width: 100%;
    padding: 163px 24px;
  }

  &__title {
    margin: 0 0 size(13px);

    font-family: $ff-mossport;
    font-weight: 400;
    font-size: size(36px);
    line-height: 125%;
    /* or 125% */

    letter-spacing: 0.01em;
    text-transform: uppercase;

    @include vp-1439 {
      margin-bottom: 13px;
      font-size: 36px;
    }

    @include vp-767 {
      font-size: 32px;
      line-height: 45px;
    }
  }

  &__list {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &__item {
    font-size: size(14px);
    line-height: 171%;

    @include vp-1439 {
      font-size: 14px;
    }
  }
}
