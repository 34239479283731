.share {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: size(20px) 0;
  border-top: 1px solid rgba($color-nero, 0.2);
  margin-top: size(40px);

  &__text {
    font-weight: 700;
    font-size: size(14px);
    line-height: 171%;
    color: $color-nero;
    margin: 0;
    padding-right: 15px;
  }

  .ya-share2__link,
  .ya-share2__item_copy .ya-share2__link_copy {
    width: size(36px);
    height: size(36px);
    border-radius: 50%;
    border: 1px solid #F3F4F4;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ya-share2__container_shape_round.ya-share2__container_size_m .ya-share2__badge .ya-share2__icon:not(.ya-share2__icon_messenger-contact):not(.ya-share2__icon_more):not(.ya-share2__icon_copy) {
    background-size: size(18px);
    background-repeat: no-repeat;
    background-position: center;
  }

  .ya-share2__container_color-scheme_whiteblack .ya-share2__item_copy .ya-share2__icon {
    background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.5 3C6.8014 2.6986 8.11825 1.41019 8.51205 1.24707C8.90585 1.08396 9.32793 1 9.75417 1C10.1804 1 10.6025 1.08396 10.9963 1.24707C11.3901 1.41019 11.7479 1.64928 12.0493 1.95068C12.3507 2.25208 12.5898 2.6099 12.7529 3.0037C12.916 3.3975 13 3.81958 13 4.24583C13 4.67207 12.916 5.09415 12.7529 5.48795C12.5898 5.88175 11.3014 7.1986 11 7.5M7.91806 10.6722L6.54097 12.0493C6.23957 12.3507 5.88175 12.5898 5.48795 12.7529C5.09415 12.916 4.67207 13 4.24583 13C3.38498 13 2.55939 12.658 1.95068 12.0493C1.34197 11.4406 1 10.615 1 9.75417C1 8.89333 1.34197 8.06774 1.95068 7.45903L3.32777 6.08194M5.16388 8.83612L8.5 5.5' stroke='%23282828' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    background-size: size(12px);
    background-repeat: no-repeat;
    background-position: center;
  }
}
