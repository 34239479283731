.open-book {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 12;

  width: 100%;
  min-height: 100vh;
  overflow: hidden;
  overflow-y: auto;

  background-color: #fdfdfd;
  visibility: hidden;
  opacity: 0;

  transition: opacity 1.5s ease, visibility 1.5s ease;

  pointer-events: none;

  @include vp-1024 {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 92px;

    background-image: linear-gradient(180deg, rgba(231,231,231,1) 22%, rgba(255,255,255,1) 47%);
  }
  @include vp-767 {
    align-items: flex-start;
    padding-top: 80px;
    background-color: $color-whisper;
    background-image: none;
  }

  &.show {
    visibility: visible;
    opacity: 1;

    pointer-events: all;
  }

  &__wrapper {
    max-height: calc(100vh - 10px);

    @include vp-767 {
      position: relative;
    }
  }

  &__hover-bg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    opacity: 0;

    // transition: opacity $trans-default;

    background-color: rgba(255, 255, 255, .5);

    pointer-events: none;

    transition: opacity 0.6s ease;

    &.is-show {
      opacity: 1;
    }
  }

  &__layout {
    position: relative;
    max-height: calc(100vh - 10px);

    margin: 0 auto;

    z-index: 10;

    @include vp-767 {
      display: flex;
      flex-wrap: wrap;
      // height: 410px;
      justify-content: start;
      // overflow-x: auto;
    }

    img {
      width: 100%;

      @include vp-767 {
        object-fit: cover;
      }
    }
  }

  &__hover-block {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;

    svg {
      width: 100%;
      height: auto;
    }
  }

  &__layout-bg {
    position: relative;
    z-index: 0;

    opacity: 0;

    transition: opacity 0.6s ease;

    pointer-events: none;

    &.is-active {
      opacity: 1;
    }
  }

  &__hover-item {
    position: relative;

    outline: 0;
    //cursor: pointer;
    opacity: 0;

    transition: opacity 0.5s ease-in-out;

    fill: $color-default-white;

    &:hover,
    &:focus {
      z-index: 1000;

      opacity: 0;
    }

    // &.is-hidden {
    //   opacity: 0.55;
    //   z-index: -1;
    // }

    // &.is-active {
    //   opacity: 0;
    // }
  }

  &__hover-text {
    opacity: 0;

    transition: opacity 0.5s ease;

    pointer-events: none;

    &.is-active {
      opacity: 1;
    }
  }

  &__tooltip-block {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
  }

  &__tooltip {
    position: absolute;
    z-index: 99;

    &--0 {
      bottom: 23.8%;
      left: 28.1%;

      @include vp-767 {
        left: 16.5%;
        bottom: 22%;
      }
    }

    &--1 {
      bottom: 51%;
      left: 39.3%;

      @include vp-767 {
        left: 35.7%;
        bottom: 50%;
      }
    }

    &--2 {
      bottom: 45%;
      left: 31.9%;

      @include vp-767 {
        bottom: 41%;
        left: 22.9%;
      }
    }

    &--3 {
      top: 50.6%;
      left: 24.3%;

      @include vp-767 {
        top: 48.2%;
        left: 12.6%;
      }
    }

    &--4 {
      bottom: 51.1%;
      left: 53.1%;

      @include vp-767 {
        bottom: 53%;
      }
    }

    &--5 {
      right: 50.6%;
      bottom: 35%;

      @include vp-767 {
        right: 48.6%;
        bottom: 34%;
      }
    }

    &--6 {
      right: 30.4%;
      bottom: 25%;

      @include vp-767 {
        right: 21.4%;
        bottom: 23%;
      }
    }

    &--7 {
      right: 36.1%;
      bottom: 47.7%;

      @include vp-767 {
        right: 28.1%;
      }
    }
  }

  &__btns {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__btn {
    position: absolute;
    bottom: size(55px);
    left: 41%;
    z-index: 12;

    transform: translateX(-50%);

    @include vp-1279 {
      left: 37%;
    }

    @include vp-1024 {
      bottom: -8%;
      left: 34%;
    }

    @include vp-767 {
      bottom: 3%;
      left: 31%;

      // width: 68px;
      // height: 68px;
      //position: relative;
      //left: 0;
      //bottom: 0;
      //margin-top: -25px;
      //// bottom: 0;
      //transform: none;
      //margin-left: auto;
      // left: 28%;
    }

    &--share {
      height: size(91px);
      top: size(99px);
      right: 10%;
      left: auto;
      width: size(91px);
      color: $color-dark-slate-blue;

      .btn__icon-wrp {
        background-color: $color-dark-slate-blue;
      }

      @include vp-1439 {
        width: 91px;
        height: 91px;
      }

      @include vp-1279 {
        right: 10%;
        left: auto;
      }

      @include vp-1024 {
        top: 30px;
        right: 10%;
        left: auto;
      }

      @include vp-767 {
        position: absolute;
        top: auto;
        bottom: calc(3% - 150px);
        right: 14%;
        left: auto;
        width: auto;
        height: auto;

        //width: 68px;
        //height: 68px;
        //right: 0;
        //top: 0;
        //bottom: 0;
        //left: 0;
        //margin-right: auto;
      }

      @include has-hover {
        .btn__icon-wrp {
          background-color: $color-alizarin;
        }

        .btn__title {
          color: $color-alizarin;
        }
      }
    }

    &--blue {
      color: $color-dark-slate-blue;
      position: absolute;
      bottom: size(55px);
      right: 36%;
      left: auto;
      z-index: 12;
      transform: translateX(-50%);

      @include vp-1279 {
        right: 32%;
        left: auto;
      }

      @include vp-1024 {
        bottom: -8%;
        right: 29%;
        left: auto;
      }

      @include vp-767 {
        right: 14%;
        left: auto;
      }

      .btn__icon-wrp {
        background-color: $color-dark-slate-blue;
      }

      @include has-hover {
        .btn__icon-wrp {
          background-color: $color-alizarin;
        }

        .btn__title {
          color: $color-alizarin;
        }
      }
    }
  }

  &__svg {
    &--desktop {
      @include vp-767 {
        display: none;
      }
    }

    &--mobile {
      display: none;
      @include vp-767 {
        display: block;
      }
    }
  }
}
