.error-block {
  min-height: 100vh;
  padding-top: size(179px);
  padding-bottom: size(80px);

  @include vp-1439 {
    padding-top: 179px;
    padding-bottom: 80px;
  }

  @include vp-767 {
    padding-top: 172px;
  }

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__icon {
    max-width: size(712px);
    margin-bottom: size(61px);

    @include vp-1439 {
      max-width: 712px;
      margin-bottom: 67px;
    }

    @include vp-767 {
      margin-left: 23px;
      margin-right: 25px;
      margin-bottom: 19px;
    }

    svg {
      width: 100%;
      height: auto;
    }
  }

  &__title {
    margin: 0 0 size(56px) -#{size(10px)};
    font-family: $ff-mossport;
    font-weight: 400;
    font-size: size(68px);
    line-height: 97%;

    letter-spacing: 0.01em;
    text-transform: uppercase;

    @include vp-1439 {
      font-size: 68px;
    }

    @include vp-767 {
      margin-left: 0;
      font-size: 32px;
      line-height: 100%;
      margin-bottom: 46px;
    }
  }

  &__btn {
    margin-left: -#{size(100px)};

    @include vp-767 {
      margin-left: -75px;
    }

    .btn__title {
      top: 87%;

      @include vp-767 {
        top: 94%;
        left: calc(100% + 10px);
        transform: translateY(-50%);
      }
    }
  }
}
