.modal-form-success {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  &__title {
    font-family: $ff-mossport;
    font-weight: 400;
    font-size: size(36px);
    line-height: 125%;
    text-align: center;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    color: $color-nero;
    margin: size(30px) 0 size(27px);

    @include vp-767 {
      font-size: size(28px);
      line-height: 121%;
      margin: size(42px) 0 size(30px);
    }
  }

  &__text {
    font-weight: 400;
    font-size: size(14px);
    line-height: 170%;
    text-align: center;
    color: $color-nero;
    max-width: 450px;
    margin: 0;
  }

  .btn-square {
    margin: size(56px) 0 0 0;

    @include vp-767 {
      margin-top: 29px;
    }
  }

  img {
    @include vp-767 {
      width: 68px;
      height: 68px;
    }
  }
}
