.timeline-controls {
  // position: relative;
  // z-index: 0;

  width: 100%;
  overflow: hidden;
  padding-bottom: size(25px);

  background-color: #f6f7f8;

  @include vp-767 {
    padding-bottom: 7px;
    overflow: hidden;
  }

  &__progress {
    position: relative;

    width: 100%;
    height: size(16px);

    background-color: $color-white-pointer;

    @include vp-767 {
      overflow: hidden;
      height: 4px;
    }
  }

  &__progress-bar {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    background-color: $color-alizarin-2;
    transform: scaleX(0.1);
    transform-origin: left;

    transition: transform $trans-default;
  }

  &__pagination {
    position: relative;
    bottom: 0 !important;

    display: flex;
    align-items: center;

    margin-top: size(32px);
    margin-left: size(132px);
    overflow-y: auto;

    @include vp-1439 {
      margin-left: 60px;
    }

    @include vp-1279 {
      margin-left: 40px;
      overflow-x: auto;
    }

    @include vp-767 {
      margin: 19px 0 5px;
      padding-left: 27px;
      padding-right: 24px;
      padding-bottom: 12px;
      overflow-x: auto;

      &:after {
        content: "b";
        max-width: 27px;
        width: 27px;
        height: 100%;
        display: block;
        opacity: 0;
      }
    }
  }

  &__bullet {
    display: block;
    width: auto;
    height: auto;
    margin: 0 size(78px) 0 0 !important;

    font-weight: 400;
    font-size: size(28px);
    line-height: 100%;
    font-family: $ff-mossport;

    background-color: transparent;
    opacity: 1;

    outline: none;

    transition: color $trans-default;

    @include vp-767 {
      margin-right: 52px !important;

      &:last-child {
        margin-right: 0 !important;;
      }
    }

    @include has-hover {
      color: $color-dark-slate-blue;
    }

    &.swiper-pagination-bullet-active {
      color: $color-alizarin;
      pointer-events: none;
    }
  }
}
