.tooltip {
  opacity: 0;
  visibility: hidden;

  width: 0;
  height: 0;

  background-color: transparent;
  border-radius: 50%;
  transform: translateZ(0);

  transition: opacity $trans-default;

  // @include vp-1439 {
  //   width: 21px;
  //   height: 21px;
  // }

  // @include vp-1023 {
  //   width: 24px;
  //   height: 24px;
  // }

  // @include vp-767 {
  //   width: 18px;
  //   height: 18px;
  // }

  &.is-open {
    z-index: 100;
  }

  &__btn {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: 0;

    display: flex;
    justify-content: center;
    align-items: center;
    width: 0;
    height: 0;
    padding: 0;

    background-color: transparent;
    border: none;
    cursor: pointer;

    transform: translateZ(0);

    transition: opacity $trans-default;

    svg {
      transform: translateZ(0);
      color: $color-alizarin;
      width: size(9px);
      height: size(9px);
      transition: color $trans-default, opacity $trans-default;

      @include vp-1439 {
        width: 9px;
        height: 9px;
      }
    }

    &:hover,
    &:focus {
      svg {
        color: $color-dark-slate-blue;
      }
    }
  }

  &__content {
    position: absolute;
    top: -#{size(25px)};
    left: -#{size(25px)};

    width: size(456px);
    padding: size(52px) size(53px) size(31px) size(57px);

    background-color: $color-default-white;
    visibility: hidden;
    opacity: 0;

    transition: opacity $trans-default, visibility $trans-default;

    pointer-events: none;

    @include vp-1439 {
      top: -25px;
      left: -25px;

      width: 456px;
      padding: 52px 53px 31px 57px;
    }

    @include vp-767 {
      max-width: 280px;
      padding: 30px 16px 16px 30px;
    }

    .is-open & {
      visibility: visible;
      opacity: 1;

      pointer-events: all;
    }
  }

  &__close-btn {
    position: absolute;
    top: size(10px);
    left: size(10px);

    padding: 0;

    background-color: transparent;
    border: none;
    cursor: pointer;

    transform: translateZ(0);
    transition: color $trans-default;

    color: $color-alizarin;

    @include vp-1439 {
      top: 10px;
      left: 10px;
    }

    svg {
      color: inherit;

      @include vp-767 {
        width: 18px;
        height: 18px;
      }
    }

    @include has-hover {
      color: $color-dark-slate-blue;
    }
  }

  &__text {
    margin: 0;

    font-weight: 400;
    font-size: size(24px);
    line-height: size(32px);
    font-family: $ff-mossport;
    text-transform: uppercase;
    letter-spacing: 0.01em;

    @include vp-1439 {
      font-size: 24px;
      line-height: 32px;
    }

    @include vp-767 {
      font-size: 14px;
      line-height: 133%;
    }
  }
}
