.custom-input,
.custom-textarea {
  position: relative;

  display: flex;
  flex-direction: column;
  width: auto;

  font-size: size(14px);

  caret-color: $color-alizarin;

  @include vp-1439 {
    font-size: 14px;
  }

  label {
    display: flex;
    align-items: center;
    width: 100%;

    input,
    textarea {
      flex-grow: 1;
      // margin-left: 30px;

      font-size: inherit;
      color: $color-default-black;

      border-radius: 0;
      outline: none;

      transition: border-color $trans-default;

      @include vp-1439 {
        font-size: 14px;
      }

      @include vp-767 {
        font-size: 16px;
      }

      &:focus {
        & ~ .custom-input__label,
        & ~ .custom-textarea__label {
          opacity: 0;
        }
      }

      &::placeholder {
        font-size: inherit;
        line-height: inherit;
        color: rgba($color-default-black, 0.4);
      }
    }

    input {
      min-height: size(54px);
      padding: size(20px) size(24px) size(16px);
      background-color: $color-snow;
      border: size(1px) solid $color-snow;

      @include vp-1439 {
        min-height: 54px;
        padding: 20px 24px 16px;
        border: 1px solid $color-snow;
      }

      @include vp-767 {
        min-height: 48px;
        padding: 11px 19px 10px;
      }

      &:hover {
        border-color: $color-dark-slate-blue;
      }

      &:focus {
        border-color: $color-dark-slate-blue;
      }
    }

    textarea {
      margin: size(4px) size(4px) 0 size(24px);
      padding: size(24px) size(18px) size(24px) 0;
      border: none;
      background-color: $color-transparent;

      @include vp-1439 {
        margin: 4px 4px 0 24px;
        padding: 24px 18px 24px 0;
      }

      @include vp-1023 {
        line-height: 100%;
      }

      @include vp-767 {
        margin: 4px 4px 0 20px;
        padding: 13px 10px 13px 0;
        line-height: 114%;
      }
    }
  }

  &__label {
    position: absolute;
    font-size: inherit;
    line-height: inherit;
    color: rgba($color-default-black, 0.4);
    cursor: text;
    pointer-events: none;
  }

  &__error {
    // position: absolute;
    // left: 0;
    // bottom: -5px;

    // max-height: 0;

    font-size: size(12px);
    line-height: 117%;
    color: $color-alizarin;

    opacity: 0;

    transition: all $trans-default;

    @include vp-1439 {
      font-size: 12px;
    }
  }

  &__blue-text {
    color: $color-dark-slate-blue;
  }

  &.is-invalid {
    .custom-input__error,
    .custom-textarea__error {
      // max-height: 32px;
      opacity: 1;
    }

    input {
      border-color: $color-alizarin;
    }
  }

  &.is-valid {
    .custom-textarea__label,
    .custom-input__label {
      opacity: 0;
    }
  }

  /*&.is-valid {
    input {
      border: 2px solid $color-malachite;

      &:focus,
      &:hover {
        border: 2px solid rgba($color-malachite, 0.5);
      }
    }
  }*/
}

.custom-input {
  line-height: 100%;

  label {
    padding: 0 0 size(6px);

    @include vp-1439 {
      padding: 0 0 6px;
    }
  }

  &__label {
    top: size(22px);
    left: size(23px);

    @include vp-1439 {
      top: 22px;
      left: 23px;
    }

    @include vp-767 {
      top: 17px;
      left: 20px;
      line-height: 100%;
    }
  }

  &--file {
    width: max-content;

    label {
      padding: 0;
      cursor: pointer;

      @include has-hover {
        .custom-input__file-icon {
          &--folder {
            color: $color-alizarin;
          }

          &--plus {
            color: $color-default-white;
          }
        }
      }

      input {
        min-height: auto;
        padding: 0;
      }

      .custom-input__file-icon-wrp {
        position: relative;
      }

      .custom-input__file-icon {
        &--folder {
          flex-shrink: 0;
          width: size(45px);
          height: size(38px);
          color: $color-white-smoke-2;
          transition: all $trans-default;

          @include vp-1439 {
            width: 45px;
            height: 38px;
          }
        }

        &--plus {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          flex-shrink: 0;
          width: size(12px);
          height: size(12px);
          color: $color-alizarin;
          transition: all $trans-default;

          @include vp-1439 {
            width: 12px;
            height: 12px;
          }
        }
      }
    }

    .custom-input {
      &__label {
        position: relative;
        top: auto;
        left: auto;
        display: flex;
        flex-direction: column;
        margin: size(6px) 0 0 size(20px);
        cursor: pointer;

        @include vp-1439 {
          margin: 6px 0 0 20px;
        }
      }

      &__label-text {
        margin: 0 0 size(6px);
        line-height: 100%;
        color: $color-nero;

        @include vp-1439 {
          margin: 0 0 6px;
        }
      }

      &__label-subtext {
        font-size: size(11px);
        line-height: 100%;
        color: rgba($color-nero, 0.5);
        margin: 0 0 size(-2px);

        @include vp-1439 {
          font-size: 11px;
          margin: 0 0 -2px;
        }
      }
    }
  }
}

.custom-textarea {
  line-height: 114%;

  label {
    position: relative;
    margin: 0 0 size(6px);
    padding: 0 0 size(64px);
    background-color: $color-snow;
    border: size(1px) solid $color-snow;
    overflow: hidden;
    transition: all $trans-default;
    cursor: text;

    @include vp-1439 {
      margin: 0 0 6px;
      padding: 0 0 64px;
      border: 1px solid $color-snow;
    }

    &:hover {
      border-color: $color-dark-slate-blue;
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: calc(100% - #{size(22px)});
      height: size(28px);
      background: linear-gradient(180deg, $color-snow 20.45%, rgba($color-snow, 0) 100%);
      pointer-events: none;

      @include vp-1439 {
        width: calc(100% - 22px);
        height: 28px;
      }

      @include vp-767 {
        height: 17px;
      }
    }

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: calc(100% - #{size(22px)});
      height: size(90px);
      background: linear-gradient(0deg, $color-snow 60.45%, rgba($color-snow, 0) 100%);
      pointer-events: none;

      @include vp-1439 {
        width: calc(100% - 22px);
        height: 90px;
      }

      @include vp-767 {
        height: 80px;
      }
    }
  }

  &__label {
    top: 0;
    left: 0;
    width: 100%;
    padding: size(28px) size(30px) size(16px) size(24px);
    pointer-events: none;

    @include vp-1439 {
      padding: 28px 30px 16px 24px;
    }

    @include vp-1023 {
      line-height: 100%;
    }

    @include vp-767 {
      padding: 17px 31px 16px 20px;
      line-height: 114%;
    }
  }

  &__message {
    position: absolute;
    bottom: size(33px);
    left: size(24px);
    font-size: size(11px);
    line-height: 218%;
    color: rgba($color-nero, 0.5);
    pointer-events: none;

    @include vp-1439 {
      bottom: 33px;
      left: 24px;
      font-size: 11px;
    }

    @include vp-1023 {
      bottom: 43px;
    }

    @include vp-767 {
      display: none;
    }
  }

  &.is-active {
    label {
      border-color: $color-dark-slate-blue;
    }
  }

  &.is-invalid {
    label {
      border-color: $color-alizarin;
    }
  }
}
