.timeline {
  position: relative;

  padding-bottom: size(40px);

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    height: size(42px);
    width: 100%;
    background-color: #f6f7f8;

    @include vp-767 {
      display: none;
    }
  }

  @include vp-767 {
    overflow: visible;
    padding-bottom: 0;
  }

  &__btns {
    display: flex;
    flex-direction: column;

    @include vp-767 {
      order: 1;
    }
  }

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;

    @include vp-767 {
      display: none;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__slides {
    position: relative;
    z-index: 1;

    overflow: hidden;

    width: 100%;
    // min-height: size(759px);

    @include vp-1023 {
      // min-height: size(700px);
    }

    @include vp-767 {
      min-height: auto;
    }
  }

  &__controls {
    position: sticky;
    bottom: 0;
    z-index: 100;
    @include vp-767 {
      position: sticky;
      bottom: 0;
      z-index: 100;
    }
    // position: fixed;
    // bottom: 0;
  }

  &__item {
    width: 100%;
    height: 100%;

    margin-top: auto;
  }
}
