[data-animate] {
  .fit-15,
  &.fit-15 {
    opacity: 0;
    transform: translateY(15%);
  }

  .fit-100,
  &.fit-100 {
    opacity: 0;
    transform: translateY(100%);
  }

  .mask-up,
  &.mask-up {
    clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0 100%);
  }

  .fade-in,
  &.fade-in {
    opacity: 0;
  }

  .up-scale,
  &.up-scale {
    opacity: 0;
    transform: translateY(50%) scale(0);
  }

  &.d-200,
  .d-200 {
    animation-delay: 0.2s;
  }

  &.d-400,
  .d-400 {
    animation-delay: 0.4s;
  }

  &.d-600,
  .d-600 {
    animation-delay: 0.6s;

    &::before,
    &::after {
      animation-delay: 0.6s;
    }
  }

  &.d-700,
  .d-700 {
    animation-delay: 0.7s;

    &::before,
    &::after {
      animation-delay: 0.7s;
    }
  }

  .d-800,
  &.d-800 {
    animation-delay: 0.8s;

    &::before,
    &::after {
      animation-delay: 0.8s;
    }
  }

  &.d-900,
  .d-900 {
    animation-delay: 0.9s;

    &::before,
    &::after {
      animation-delay: 0.9s;
    }
  }

  .d-1000,
  &.d-1000 {
    animation-delay: 1s;

    &::before,
    &::after {
      animation-delay: 1s;
    }
  }

  .d-1200,
  &.d-1200 {
    animation-delay: 1.2s;

    &::before,
    &::after {
      animation-delay: 1.2s;
    }
  }

  .d-1400,
  &.d-1400 {
    animation-delay: 1.4s;

    &::before,
    &::after {
      animation-delay: 1.4s;
    }
  }

  .d-1600,
  &.d-1600 {
    animation-delay: 1.6s;

    &::before,
    &::after {
      animation-delay: 1.6s;
    }
  }

  &.show {
    .fit-15,
    &.fit-15 {
      animation-name: fade-in-top-15;
      animation-duration: .4s;
      animation-timing-function: ease-in-out;
      animation-fill-mode: forwards;
    }

    .fit-100,
    &.fit-100 {
      animation-name: in-top-100;
      animation-duration: .3s;
      animation-timing-function: ease-in-out;
      animation-fill-mode: both;
    }

    .fade-in,
    &.fade-in {
      animation-name: fade-in;
      animation-duration: 0.5s;
      animation-timing-function: ease-in-out;

      animation-fill-mode: both;
    }

    .up-scale,
    &.up-scale {
      animation-name: up-scale;
      animation-duration: 0.3s;
      animation-timing-function: ease-in-out;

      animation-fill-mode: both;
    }

    .mask-up,
    &.mask-up {
      animation-name: maskUp;
      animation-duration: .4s;
      animation-timing-function: ease-in-out;
      animation-fill-mode: forwards;
    }
  }


}
