.empty-slide {
  display: flex;
  flex-direction: column;
  align-items: center;

  padding-bottom: size(86px);
  padding-top: size(60px);

  @include vp-1599 {
    padding-bottom: size(86px);
    padding-top: size(70px);
  }

  @include vp-767 {
    padding: 50px 16px 100px;
  }

  &__button-block {
    display: grid;
    grid-column-gap: size(14px);
    grid-template-columns: size(355px) auto 1fr;
    grid-template-rows: auto 1fr;
    align-self: flex-start;

    @include vp-767 {
      display: flex;
      flex-direction: column;
      order: 10;
      width: 100%;
      margin-top: auto;
    }
  }

  &__img {
    width: size(462px);
    margin-bottom: size(48px);

    @include vp-1599 {
      width: size(662px);
    }

    svg {
      width: 100%;
      height: auto;
    }

    @include vp-767 {
      max-width: 662px;
      width: 100%;
    }
  }

  &__title {
    margin: 0 0 size(5px);
    font-family: $ff-mossport;
    font-weight: 400;
    font-size: size(70px);
    line-height: 100%;

    color: $color-alizarin;

    letter-spacing: 0.01em;
    text-transform: uppercase;

    @include vp-767 {
      font-size: 40px;
    }
  }

  &__text {
    margin: 0 0 size(16px);
    max-width: size(679px);

    font-weight: 400;
    font-size: size(16px);
    line-height: 150%;

    text-align: center;
  }

  &__btn {
    margin-left: -#{size(50px)};

    @include vp-767 {
      margin: 0 auto;
    }

    .btn__icon {
      width: size(77px);
      height: size(77px);

      @include vp-767 {
        width: 68px;
        height: 68px;
      }
    }
  }
}
