.modal-info {
  padding: size(72px) size(80px) size(60px);

  box-shadow: 0 0 30px rgba(177, 182, 187, 0.15);

  @include vp-767 {
    padding: 92px 8px 53px;
  }

  &__title {
    margin: 0 size(20px) size(40px);

    font-style: normal;
    font-weight: 400;
    font-size: size(56px);
    line-height: 100%;
    font-family: $ff-mossport;
    text-align: center;
    color: $color-alizarin;
    text-transform: uppercase;
    letter-spacing: 0.01em;

    @include vp-767 {
      margin-bottom: 40px;

      font-size: 48px;
      line-height: 49px;
    }
  }

  &__description {
    margin: 0 size(20px);

    p {
      margin: 0 0 size(40px);

      font-weight: 400;
      font-size: size(14px);
      line-height: 171%;

      &:last-child {
        margin-bottom: 0;
      }

      @include vp-767 {
        margin-bottom: 24px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
