.intro {
  position: relative;

  min-height: 100vh;
  height: 100%;
  // padding-bottom: size(84px);
  overflow: hidden;

  background: $color-gainsboro;

  display: flex;
  align-items: center;
  transition: opacity 0.5s ease, visibility 0.5s ease;

  &.is-hidden {
    overflow: hidden;
    max-height: 100vh;
  }

  @include vp-1024 {
    min-height: 100vh;
    height: 100%;

    background-color: $color-whisper;
  }
  @include vp-767 {
    height: 100%;
    min-height: 100vh;
  }

  &__sequence {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    width: 100%;
    min-height: 100vh;
    height: 100%;

    @include vp-1024 {
      width: 100%;
      min-height: calc(100vh - 92px);
      // height: 808px;
      margin-top: 92px;
    }
    @include vp-767 {
      min-height: 410px;
      height: 410px;
      margin-top: 80px;

      // &:after {
      //   content: "";
      //   position: absolute;
      //   bottom: -220px;
      //   left: 0;

      //   width: 100%;
      //   height: 232px;

      //   background: $color-whisper-2;
      // }
    }

    canvas {
      position: absolute;
      top: 0;
      left: 0;

      width: 100%;
      height: 100%;

      z-index: 1;

      // opacity: 0;
      // max-width: 100vw;
      // height: 100vh;
      // max-height: 100vh;

      @include vp-767 {
        // position: relative;

        // height: 410px;
      }
    }
  }

  &__sequence-bg {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
    min-height: 100vh;

    z-index: 0;

    pointer-events: none;
    opacity: 0;

    transition: opacity 1s ease;

    @include vp-1024 {
      // display: flex;
      // align-items: center;
      // height: 808px;
      // min-height: auto;
    }
    @include vp-767 {
      min-height: 410px;
      height: 410px;
    }

    img {
      width: 100%;
      height: auto;
      position: relative;

      object-fit: cover;

      @include vp-767 {
        position: absolute;
        top: 0;
      }
    }

    &.show {
      opacity: 1;
    }
  }

  &__launch-btn {
    position: absolute;
    top: 80%;
    opacity: 1;

    z-index: 1;

    display: none;

    will-change: transform;
    transform: translateZ(0);

    transition: opacity $trans-default, visibility $trans-default;

    @include vp-1024 {
      top: 66%;
      transform: translateX(-50%);
    }

    @include vp-767 {
      top: 455px;
    }

    .btn__icon-wrp {
      @include vp-1024 {
        transform: scale(0);
      }
    }

    &.is-show {
      display: block;

      .btn__title {
        animation: maskRight-50 0.8s linear;
      }

      .btn__icon-wrp {
        animation: scale-100 0.8s ease-in-out;

        @include vp-1024 {
          transform: scale(1);
        }
      }
    }

    // &.is-hidden {
    //   // display: none;
    //   opacity: 0;
    //   visibility: hidden;
    // }

    &.hide-animate {
      .btn__title {
        animation: maskLeft-hide 0.8s linear;

        clip-path: polygon(0 0, 0 0, 0 100%, 0 100%);
      }

      @include vp-1024 {
        .btn__icon-wrp {
          transform: scale(0);
          animation: decrease-100 0.8s linear;
        }
      }
    }


    &--share,
    &--next {
      position: relative;
      left: 32%;
      right: 50%;
      top: auto;
      bottom: 0;

      .btn__icon {
        width: size(43px);
        height: size(43px);

        @include vp-1023 {
          width: 52px;
          height: 52px;
        }
      }

      .btn__title {
        font-size: size(24px);
        line-height: 101%;
        padding-left: size(10px);
        padding-top: size(18px);

        @include vp-767 {
          font-size: size(20px);
          line-height: 110%;
          padding-left: 0;
          padding-top: 0;
        }
      }

      svg {
        width: size(17px);
        height: size(17px);

        @include vp-1439 {
          width: size(17px);
          height: size(17px);
        }

        @include vp-767 {
          width: size(17px);
          height: size(17px);
        }
      }

      @include vp-1024 {
        top: auto;
        bottom: 10%;
        right: 40%;
      }
      @include vp-767 {
        position: absolute;
        right: 18%;
        top: 395px;
        left: auto;
        bottom: auto;
      }

      &.is-next-show {
        .btn__icon-wrp {
          animation: none;

          @include vp-1024 {
            animation: scale-100 0.8s ease-in-out;
          }
        }
      }

      &.hide-animate {
        .btn__icon-wrp {
          transform: scale(0);
          animation: decrease-100 0.8s ease-in-out;
        }
      }
    }

    &--share {
      left: 33.5%;
      top: 78%;
      bottom: auto;
      right: auto;
      position: absolute;

      .btn__icon {
        width: size(68px);
        height: size(68px);

        @include vp-1023 {
          width: 52px;
          height: 52px;
        }
      }

      .btn__title {
        font-size: size(41px);
        line-height: 109%;
        color: $color-dark-slate-blue;
        padding-top: size(29px);
        padding-left: size(12px);

        @include vp-767 {
          padding-left: 0;
          padding-top: 0;
        }
      }

      .btn__icon-wrp {
        background-color: $color-dark-slate-blue;
      }

      svg {
        width: size(35px);
        height: size(35px);

        @include vp-1439 {
          width: size(35px);
          height: size(35px);
        }

        @include vp-767 {
          width: size(25px);
          height: size(25px);
        }
      }

      @include has-hover {
        .btn__icon-wrp {
          background-color: $color-alizarin;
        }

        .btn__title {
          color: $color-alizarin;
        }
      }

      @include vp-1024 {
        top: 73%;
        bottom: auto;
        left: 20%;
        right: auto;
      }

      @include vp-767 {
        z-index: 15;
        left: 31%;
        right: auto;
        top: 396px;
        bottom: auto;
      }
    }
  }

  &__text-wrapper {
    position: relative;
    left: 53%;
    z-index: 1;

    max-width: size(395px);
    // padding-top: 271px;
    padding: size(50px) 0;

    visibility: visible;
    opacity: 1;

    transition: opacity $trans-default, visibility $trans-default;

    @include vp-1439 {
      max-width: 395px;
      padding: 50px 0;
    }
    @include vp-1024 {
      left: 52.7%;

      max-width: 314px;
      padding-top: 0;
      padding-bottom: 0;

      margin-top: 250px;
      margin-bottom: 220px;
    }
    @include vp-767 {
      left: 0;

      max-width: 100%;
      margin: 0 24px;
      padding-top: 560px;
      padding-bottom: 30px;
    }

    &.is-hidden {
      visibility: hidden;
      opacity: 0;
    }
  }

  &__title {
    margin: 0 0 size(29px);
    max-width: size(200px);

    font-weight: 400;
    font-size: size(68px);
    line-height: 103%;
    font-family: $ff-mossport;
    color: $color-alizarin;
    text-transform: uppercase;
    letter-spacing: 0.01em;

    transition: clip-path $trans-default;
    clip-path: polygon(0 0, 0 0, 0 100%, 0 100%);

    @include vp-1439 {
      margin-bottom: 29px;

      font-size: 68px;
    }
    @include vp-1024 {
      max-width: 100%;
      margin-bottom: 12px;

      font-size: 52px;
      line-height: 50px;
    }
    @include vp-767 {
      margin-bottom: 14px;

      font-size: 48px;
      line-height: 49px;
    }

    .is-hidden & {
      clip-path: polygon(0 0, 0 0, 0 100%, 0 100%);
    }

    .show & {
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
      animation: maskRight-50 1.2s linear;
    }

    .hide-animate & {
      animation: maskLeft-hide 1.2s linear;

      clip-path: polygon(0 0, 0 0, 0 100%, 0 100%);
    }

    span {
      display: block;
    }
  }

  &__description {
    position: relative;

    margin: 0 0 size(50px);

    font-weight: 400;
    font-size: size(14px);
    line-height: 157%;

    transform: translateY(20px);
    opacity: 0;

    transition: opacity 0.8s ease, transform 1s ease;

    @include vp-1439 {
      margin-bottom: 50px;

      font-size: 14px;
    }
    @include vp-1024 {
      margin-bottom: 31px;

      font-size: 14px;
      line-height: 20px;
    }
    @include vp-767 {
      margin-bottom: 30px;

      line-height: 22px;
    }

    .show & {
      transform: translateY(0);
      opacity: 1;

      transition-delay: 0.5s;
    }

    .hide-animate & {
      transform: translateY(20px);
      opacity: 0;

      transition-delay: 0.5s;
    }
  }

  &__blockquote {
    position: relative;

    margin: 0;
    margin-bottom: 18px;
    padding-left: size(27px);

    font-style: italic;
    font-weight: 500;
    font-size: size(12px);
    line-height: 150%;

    transform: translateY(20px);
    opacity: 0;

    transition: opacity 0.8s ease, transform 0.8s ease;

    @include vp-1439 {
      padding-left: 27px;

      font-size: 12px;
    }
    @include vp-1024 {
      max-width: 279px;
      padding-left: 1px;
    }
    @include vp-767 {
      margin-right: 21px;
      padding-left: 27px;
    }

    .hide-animate & {
      transform: translateY(20px);
      opacity: 0;

      transition-delay: 0.3s;
    }

    .show & {
      transform: translateY(0);
      opacity: 1;

      transition-delay: 0.9s;
    }


  }

  &__blockquote-text {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: -#{size(5px)};;
      left: -#{size(22px)};

      width: size(12px);
      height: size(11px);

      background-image: url("../img/sprite/quotes-icon.svg");
      background-repeat: no-repeat;
      background-position: center;

      @include vp-1439 {
        width: 12px;
        height: 11px;
      }
      @include vp-1024 {
        left: -27px;
      }
      @include vp-767 {
        left: -25px;
      }
    }

    &::after {
      content: "";
      position: absolute;
      bottom: size(3px);
      right: -#{size(20px)};

      width: size(12px);
      height: size(11px);

      transform: scale(-1, 1);

      background-image: url("../img/sprite/quotes-icon.svg");
      background-repeat: no-repeat;
      background-position: center;

      @include vp-1439 {
        width: 12px;
        height: 11px;
      }
    }
  }

  &__blockquote-author {
    display: inline-block;
    margin-top: size(30px);

    font-style: normal;

    @include vp-1439 {
      margin-top: 30px;
    }
    @include vp-1024 {
      margin-top: 22px;
    }
    @include vp-767 {
      display: block;
      margin-top: 23px;
    }
  }

  &__blockquote-pos {
    display: block;

    font-weight: 400;
    font-style: normal;
    font-size: size(10px);
    line-height: 170%;

    @include vp-1439 {
      font-size: 10px;
    }
  }
}
