.custom-radio {
  cursor: pointer;
  opacity: 1;
  transition: opacity $trans-default;
  margin-bottom: 14px;

  @include has-hover {
    opacity: 0.7;
  }

  label {
    display: flex;
    flex-direction: column;
    height: auto;
    align-items: center;
  }

  img {
    height: 65px;
  }

  &__label {
    display: inline-block;
    font-family: $ff-mossport;
    font-weight: 400;
    font-size: size(18px);
    line-height: 100%;
    text-align: center;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    color: $color-nero;
    margin-top: 6px;
  }
}
