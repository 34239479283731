.modal {
  $root: &;

  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;

  display: flex;
  flex-direction: column;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  background-color: rgba($color-matterhorn, 0.5);
  backdrop-filter: blur(5px);
  transform: scale(1.2);
  visibility: hidden;
  opacity: 0;

  transition: opacity $trans-modal,
    transform $trans-modal,
    visibility $trans-modal;

  pointer-events: none;

  //-ms-overflow-style: none;
  //scrollbar-width: none;
  //
  //&::-webkit-scrollbar {
  //  display: none;
  //}

  @include vp-1439 {
    backdrop-filter: blur(20px);
  }

  &__wrapper {
    position: relative;

    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 100%;
    padding: size(60px) size(48px);

    @include vp-1439 {
      padding: 60px 48px;
    }

    @include vp-767 {
      padding: 60px 24px;
    }
  }

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    cursor: pointer;
  }

  &__content {
    position: relative;

    // width: 600px;
    // padding: 40px;

    background-color: $color-default-white;

    @include vp-767 {
      width: 100%;
      // padding: 20px;
    }
  }

  &__close-btn {
    position: absolute;
    top: size(-33px);
    right: size(-8px);
    z-index: 1;

    width: size(33px);
    height: size(33px);
    padding: 0;

    color: $color-alizarin;
    background: $color-transparent;
    border: none;
    cursor: pointer;
    opacity: 1;
    transition: all $trans-default;

    @include vp-1439 {
      top: -33px;
      right: -8px;
      width: 33px;
      height: 33px;
    }

    @include has-hover {
      opacity: 0.6;
    }

    svg {
      transform: translateZ(0);
    }
  }

  &__bg {
    position: fixed;
    top: size(158px);
    //right: size(157px);
    right: 10.9%;
    z-index: 1;

    &:nth-of-type(2) {
      right: auto;
      //left: size(129px);
      left: 8.9%;
      top: size(128px);
    }

    @include vp-1279 {
      right: 0;

      &:nth-of-type(2) {
        right: auto;
        left: 0;
      }
    }

    @include vp-767 {
      top: 0;
      right: -30.9%;

      &:nth-of-type(2) {
        top: 0;
        right: auto;
        left: -28.9%;
      }
    }

    //@include vp-767 {
    //  display: none;
    //}
  }

  &--responsive {
    .modal__content {
      width: 100%;
    }
  }

  &--success {
    background-color: $color-default-white;

    #{$root}__overlay {
      background-color: $color-white-smoke;
    }

    #{$root}__content {
      width: 100%;
      height: 100%;
      max-width: size(680px);
      max-height: size(807px);
      padding: size(24px);
      padding-top: size(17px);
      position: relative;
      z-index: 2;
    }
  }

  &--fit-content {
    .modal__content {
      width: auto;

      @include vp-767 {
        width: 100%;
      }
    }
  }

  &--no-scale {
    transform: none;
  }

  &--preload {
    transition: none;
  }

  &.is-active {
    transform: scale(1);
    visibility: visible;
    opacity: 1;

    pointer-events: auto;
  }

  &--video {
    background-color: rgba($color-default-white, 0.9);
    backdrop-filter: none;

    #{$root}__content {
      width: 100%;
    }
  }

  &--video-mock {
    background-color: rgba($color-default-white, 0.9);
    backdrop-filter: none;

    .modal-video {
      background-color: $color-default-white;
      padding: 27px 32px 80px;
      display: flex;
      flex-direction: column;
    }

    .modal__close-btn {
      top: 17px;
      right: 20px;
      color: $color-nero;
    }
  }

  &--video-sport {
    background-color: rgba($color-default-white, 0.9);
    backdrop-filter: none;

    .modal__wrapper {
      padding-left: 12px;
      padding-right: 12px;
    }

    .modal__content {
      max-width: size(680px);
      background-color: $color-dark-slate-blue;
    }

    .modal-video__title {
      font-weight: 400;
      font-size: size(56px);
      line-height: 100%;
      letter-spacing: 0.01em;
      text-transform: uppercase;
      margin: 0 0 29px 0;
      color: $color-default-white;
      text-align: left;

      @include vp-767 {
        margin-bottom: 9px;
        font-size: size(32px);
      }
    }

    .modal-video {
      flex-direction: column;
    }

    .modal-video__text {
      font-weight: 400;
      font-size: size(14px);
      line-height: 171%;
      color: $color-default-white;
      margin: 0;
      text-align: left;
      padding-right: 49px;
    }

    .modal__close-btn {
      top: 32px;
      right: 27px;
      color: $color-default-white;
      z-index: 10;

      @include vp-767 {
        width: 10px;
        height: 10px;
        top: 13px;
        right: 18px;

        svg {
          width: 10px;
          height: 10px;
        }
      }
    }

    .modal-video__btn {
      margin-top: 5px;
    }
  }

  &--timeline {
    background-color: rgba($color-default-white, 0.9);
    backdrop-filter: none;

    @include vp-1439 {
      backdrop-filter: none;
    }

    .modal__close-btn {
      top: 0;
      right: -#{size(86px)};
      color: $color-nero;

      @include vp-1023 {
        top: 23px;
        right: 27px;
      }

      svg {
        width: size(32px);
        height: size(32px);

        @include vp-1023 {
          width: 20px;
          height: 20px;
        }

        @include vp-767 {
          color: $color-alizarin;
        }
      }
    }

    .modal__wrapper {
      padding-top: size(104px);
      @include vp-1023 {
        padding: 0;
      }
    }

    .modal__content {
      max-width: size(814px);
      @include vp-1023 {
        max-width: 100%;
        width: 100%;
        height: 100%;
      }
    }
  }

  &--info {
    background-color: rgba($color-default-white, 0.9);
    backdrop-filter: none;

    @include vp-1439 {
      backdrop-filter: none;
    }

    .modal__close-btn {
      top: 0;
      right: -#{size(86px)};
      color: $color-nero;

      @include vp-1023 {
        top: 23px;
        right: 27px;
      }

      svg {
        width: size(32px);
        height: size(32px);

        @include vp-1023 {
          width: 20px;
          height: 20px;
        }

        @include vp-767 {
          color: $color-alizarin;
        }
      }
    }

    .modal__wrapper {
      padding-top: size(104px);
      @include vp-1023 {
        padding: 0;
      }
    }

    .modal__content {
      max-width: size(814px);
      @include vp-1023 {
        max-width: 100%;
        width: 100%;
        height: 100%;
      }
    }
  }

  &--copyright {
    background-color: rgba($color-default-white, 0.9);
    backdrop-filter: none;

    @include vp-1439 {
      backdrop-filter: none;
    }

    .modal__close-btn {
      top: size(27px);
      right: size(21px);
      color: $color-nero;

      @include vp-1439 {
        top: 27px;
        right: 21px;
      }

      svg {
        width: size(22px);
        height: size(22px);

        @include vp-1439 {
          width: 22px;
          height: 22px;
        }

        @include vp-767 {
          width: 18px;
          height: 18px;
        }
      }

      @include vp-767 {
        top: 19px;
        right: 21px;
      }
    }

    .modal__wrapper {
      @include vp-767 {
        padding: 0;
      }
    }

    .modal__content {
      @include vp-767 {
        height: 100%;
      }
    }
  }
}
