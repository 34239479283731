.stars-btn {
  background: $color-alizarin;
  box-shadow: 0 9px 25px rgba(68, 71, 74, 0.12);
  border-radius: 100px;
  display: flex;
  align-items: center;
  height: size(70px);
  padding: 17px 32px;
  position: relative;
  z-index: 1;
  width: auto;
  margin-right: auto;

  &__title {
    font-family: $ff-mossport;
    font-weight: 400;
    font-size: size(47px);
    line-height: 100%;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    color: $color-default-white;
    margin: 0 8px 0 0;
  }

  &__text {
    font-family: $ff-mossport;
    font-weight: 400;
    font-size: size(20px);
    line-height: 95%;
    letter-spacing: 0.01em;
    text-transform: uppercase;
    color: $color-default-white;
    margin: 0 22px 0 0;
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: size(28px);
    height: size(28px);
    border: none;
    border-radius: 50%;
    background-color: $color-default-white;

    svg {
      color: $color-alizarin;
      opacity: 1;
      flex-shrink: 0;
      transition: opacity $trans-default;
    }
  }

  @include has-hover {
    svg {
      opacity: 0.7;
    }
  }

  @include vp-767 {
    margin:  0 auto;
  }
}
