.history-slider {
  &__main {
    position: relative;
    padding-left: size(20px);
    padding-right: size(20px);

    @include vp-767 {
      padding-left: 16px;
      padding-right: 16px;
    }
  }

  &__thumbs {
    margin-left: size(20px);
    margin-right: size(20px);
    margin-top: size(16px);
    margin-bottom: size(70px);

    @include vp-767 {
      margin-left: 16px;
      margin-right: 16px;
      margin-bottom: 60px;
    }
  }

  .swiper-wrapper {
    @include vp-767 {
      display: flex;
      align-items: center;
    }
  }

  &__slide {

    img {
      width: 100%;
      height: 100%;
      max-height: size(438px);
      margin: 0 auto;
      object-fit: contain;

      @include vp-767 {
        max-height: 300px;
      }
    }
  }

  &__thumb-slide {
    width: size(50px);
    height: size(35px);
    overflow: hidden;

    opacity: 0.4;

    cursor: pointer;

    transition: opacity $trans-default;

    @include has-hover {
      opacity: 0.7;
    }

    &.swiper-slide-thumb-active{
      opacity: 1;
      pointer-events: none;
    }

    img {
      width: 100%;
      max-height: size(35px);
      object-fit: cover;
    }
  }

  &__control {
    position: absolute;

    display: flex;
    align-items: center;
    justify-content: center;

    z-index: 10;
    width: size(40px);
    height: size(40px);
    top: calc(50% - #{size(20px)});
    color: $color-default-white;

    border-radius: 50%;
    border: none;
    background-color: $color-alizarin;

    cursor: pointer;

    svg {
      width: size(16px);
      height: auto;
    }

    transition: opacity $trans-default;

    @include has-hover {
      opacity: 0.7;
    }

    &.swiper-button-disabled {
      opacity: 0.7;
      pointer-events: none;
    }

    &--next {
      right: 1px;
    }

    &--prev {
      left: 1px;

      svg {
        transform: rotate(180deg);
      }
    }
  }
}
