.stories-navigation__list {
  width: calc(100% + #{size(21px)});
  margin-right: size(-21px);
  margin-top: 0;
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  &:empty {
    margin-bottom: 0;
  }
}

.stories-navigation__item {
  margin-right: size(21px);
  margin-bottom: size(31px);
}

.stories-navigation__link {
  position: relative;
  font-family: $ff-mossport;
  font-weight: 400;
  font-size: size(36px);
  line-height: size(38px);
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: #3B3393;
  padding-right: size(14px);

  sup {
    position: absolute;
    top: size(11px);
    right: 0;
    font-size: 55%;
  }
}

.stories-navigation__year {
  border-bottom: size(1px) dashed #3B3393;
}
