.custom-select {
  position: relative;

  // width: 250px;
  padding: 0;

  font-size: size(14px);
  line-height: 100%;

  @include vp-1439 {
    font-size: 14px;
  }

  &__label {
    position: absolute;
    top: 50%;
    left: 10px;

    transform: translateY(-50%);

    transition: transform $trans-default, opacity $trans-default;

    pointer-events: none;
  }

  &__placeholder {
    position: absolute;
    top: calc(50% + #{size(2px)});
    left: size(24px);

    color: rgba($color-default-black, 0.4);

    transform: translateY(-50%);

    transition: all $trans-default;

    pointer-events: none;

    @include vp-1439 {
      top: calc(50% + 2px);
      left: 24px;
    }

    @include vp-767 {
      top: 50%;
      left: 20px;
    }
  }

  select {
    position: absolute;

    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;

    white-space: nowrap;

    border: 0;

    clip: rect(0 0 0 0);
    clip-path: inset(100%);
  }

  &__button {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    // height: 40px;
    min-height: size(54px);
    margin: 0 0 size(6px);
    padding: size(20px) size(26px) size(16px) size(24px);

    background-color: $color-snow;
    border: size(1px) solid $color-snow;
    border-radius: 0;
    outline: none;
    cursor: pointer;

    color: $color-nero;

    transition: border-color $trans-default;

    @include vp-1439 {
      min-height: 54px;
      margin: 0 0 6px;
      padding: 20px 26px 16px 24px;
      border: 1px solid $color-snow;
    }

    @include vp-767 {
      min-height: 48px;
      padding: 15px 21px 16px 19px;
    }

    @include has-hover {
      border-color: $color-dark-slate-blue;
    }
  }

  &__list {
    position: absolute;
    bottom: size(21px);
    z-index: 1;

    width: 100%;
    // max-height: 100px;
    margin: 0;
    padding: size(22px) size(24px);
    overflow-y: auto;

    font-size: size(14px);
    line-height: 193%;

    list-style: none;

    background-color: $color-snow;
    border: size(1px) solid $color-dark-slate-blue;
    transform: translateY(100%);
    visibility: hidden;
    opacity: 0;

    transition: visibility $trans-default, opacity $trans-default;

    @include vp-1439 {
      bottom: 21px;
      padding: 22px 24px;
      font-size: 14px;
      border: 1px solid $color-dark-slate-blue;
    }
  }

  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;

    outline: none;
    cursor: pointer;

    transition: all $trans-default;

    color: $color-nero;

    &[aria-selected="true"] {
      // background-color: rgba($color-default-white, 0.2);
      color: $color-alizarin;

      // @media (hover: hover), screen and (min-width: 0\0) {
      //   &:hover,
      //   &:focus {
      //     // background-color: rgba($color-default-white, 0.2);
      //     color: $color-alizarin;
      //   }
      // }

      svg {
        opacity: 1;
      }
    }

    @media (hover: hover), screen and (min-width: 0\0) {
      &:hover,
      &:focus {
        // background-color: rgba($color-default-white, 0.4);
        color: $color-alizarin;

        svg {
          opacity: 1;
        }
      }
    }

    svg {
      flex-shrink: 0;
      width: size(11px);
      height: size(9px);
      margin: size(2px) size(2px) 0 size(16px);
      fill: $color-transparent;
      opacity: 0;
      transition: all $trans-default;

      @include vp-1439 {
        width: 11px;
        height: 9px;
        margin: 2px 2px 0 16px;
      }
    }
  }

  &__error {
    // position: absolute;
    // bottom: 0;
    // left: 0;

    // opacity: 0;

    // transition: opacity 0.1s ease;
    // max-height: 0;

    font-size: size(12px);
    line-height: 117%;
    color: $color-alizarin;

    opacity: 0;

    transition: all $trans-default;

    @include vp-1439 {
      font-size: 12px;
    }
  }

  &__text {
    flex-shrink: 0;
    width: 90%;
    overflow: hidden;

    text-align: left;
    white-space: nowrap;
    text-overflow: ellipsis;

    opacity: 0;

    transition: opacity $trans-default 0.1s;

    br {
      display: none;
    }
  }

  &__icon {
    display: flex;
    flex-shrink: 0;
    margin: size(-6px) 0 0 auto;

    // background-color: $color-default-black;
    transform: translateZ(0);

    transition: transform $trans-default;

    // clip-path: polygon(100% 0, 100% 20%, 50% 100%, 0 20%, 0 0, 50% 80%);

    @include vp-1439 {
      margin: -6px 0 0 auto;
    }

    @include vp-767 {
      margin: 0 0 0 auto;
    }

    svg {
      width: size(10px);
      height: size(6px);
      fill: $color-transparent;

      @include vp-1439 {
        width: 10px;
        height: 6px;
      }
    }
  }

  &.open-up {
    .custom-select__list {
      bottom: auto;
      top: 0;
      transform: translateY(calc(-100% + 30px));
    }
  }

  &.is-open {
    .custom-select__placeholder {
      color: $color-nero;
    }

    .custom-select__button {
      border-color: $color-dark-slate-blue;
    }

    .custom-select__list {
      visibility: visible;
      opacity: 1;
    }

    .custom-select__icon {
      transform: rotate(180deg);
    }

    .custom-select__label {
      opacity: 0;
    }
  }

  &.not-empty {
    .custom-select__text {
      opacity: 1;
    }

    .custom-select__label {
      opacity: 0;
    }

    .custom-select__placeholder {
      opacity: 0;
    }
  }

  &.is-invalid {
    .custom-select__error {
      // max-height: 100%;
      opacity: 1;
    }

    .custom-select__button {
      border-color: $color-alizarin;
    }
  }

  /* &.is-valid {
    .custom-select__button {
      border: 2px solid $color-eucalyptus;

      &:focus,
      &:hover {
        border: 2px solid rgba($color-eucalyptus, 0.5);
      }
    }
  } */

  &--placeholder {
    .custom-select__label {
      transform: translateY(calc(-50% - 40px));
      opacity: 1;
    }

    &.is-open .custom-select__label {
      opacity: 1;
    }

    &.not-empty .custom-select__label {
      opacity: 1;
    }
  }

  &.slide-up.not-empty,
  &.slide-up.is-open {
    .custom-select__label {
      transform: translateY(calc(-50% - 40px));
      opacity: 1;
    }

    .custom-select__text {
      opacity: 1;
    }
  }
}
