.loader {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;

  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  background-color: $color-default-white;

  transition: opacity 0.6s ease;

  &.is-active {
    display: flex;
  }

  &.is-hidden {
    opacity: 0;
  }

  &__logo {
    width: size(1001px);
    min-height: size(413px);
    margin-top: -#{size(110px)};

    @include vp-1439 {
      width: 1001px;
      min-height: 413px;
      margin-top: -110px;
    }
    @include vp-1024 {
      width: 100%;
    }
    @include vp-767 {
      position: relative;

      width: calc(100% + 170px);
      margin-top: -274px;
    }
  }

  &__line {
    width: size(317px);
    height: size(2px);
    margin-top: -#{size(34px)};

    background-color: $color-gainsboro;

    @include vp-1439 {
      width: 317px;
      height: 2px;
      margin-top: -34px;
    }
    @include vp-1024 {
      margin-top: -63px;
    }
    @include vp-767 {
      width: 189px;
      margin-top: -103px;
    }
  }

  &__progress {
    width: 0;
    height: 100%;

    background-color: $color-alizarin;
  }
}
