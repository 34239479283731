.container {
  width: 100%;
  margin: 0 auto;
  padding: 0 size(100px);

  @include vp-1439 {
    padding: 0 100px;
  }

  @include vp-1023 {
    padding: 0 60px;
  }

  @include vp-767 {
    padding: 0 24px;
  }
}
